import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon } from 'react-icons/hi2';
import { Badge, Button, Input, Label } from '@knack/asterisk-react';

import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { ViewColumnDynamicActions } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/ViewColumnDynamicActions';

interface ViewWithDetailsActionColumnSettingsProps {
  fieldInput: DetailsViewInput;
  updateListColumn: (fieldInput: Partial<DetailsViewInput>) => void;
}

export function ViewWithDetailsActionColumnSettings({
  fieldInput,
  updateListColumn
}: ViewWithDetailsActionColumnSettingsProps) {
  const [t] = useTranslation();

  const shouldRenderDynamicActions = fieldInput.type === 'action_link';

  return (
    <BuilderAccordion>
      {shouldRenderDynamicActions ? (
        <ViewColumnDynamicActions<DetailsViewInput>
          columnItem={fieldInput}
          updateViewColumn={updateListColumn}
        />
      ) : (
        // TODO: Implement this settings after adding DnD and settings in the Live-app
        <BuilderAccordion.Item
          label="pages.element_settings.common.categories.actions.action_column_properties"
          isDefaultOpen
        >
          <div>
            <Label>
              {t(
                'pages.element_settings.common.categories.data_display.field_management.button_text'
              )}
              <Input
                placeholder={t('actions.delete')}
                className="mt-2"
                value={fieldInput.link_text}
                onChange={(e) => updateListColumn({ link_text: e.target.value })}
              />
            </Label>
          </div>
          <div className="mt-4">
            <p>{t('keywords.icon')}</p>
            <Button intent="secondary" className="mt-2 items-center" size="sm" disabled>
              <Button.Icon icon={AddIcon} />
              {t('keywords.icon')}
            </Button>
            <Badge className="ml-2">{t('keywords.coming_soon')}</Badge>
          </div>
        </BuilderAccordion.Item>
      )}
    </BuilderAccordion>
  );
}
