import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
  Banner,
  Checkbox,
  Divider,
  Input,
  Label,
  RichTextEditor,
  Select
} from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  FORM_VIEW_SUBMIT_RULE_ACTION_TYPES,
  type FormViewSubmitRule
} from '@/types/schema/views/FormView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { cn } from '@/utils/tailwind';
import { CriteriaForm } from '@/components/CriteriaForm';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';

interface SubmitRuleFormProps {
  submitRule: FormViewSubmitRule;
  activePage: BuilderPage;
  pages: BuilderPage[];
  sourceObject: KnackObject;
  initialSubmitRuleValues: FormViewSubmitRule;
  isActionSubmitRule?: boolean;
  canCriteriaValuesBeField?: boolean;
}

export function SubmitRuleFormFields({
  submitRule,
  activePage,
  pages,
  sourceObject,
  initialSubmitRuleValues,
  isActionSubmitRule,
  canCriteriaValuesBeField
}: SubmitRuleFormProps) {
  const [t] = useTranslation();
  const { getEligibleLinkablePagesFromObjectKey } = usePageHelpers();
  const {
    register,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext<FormViewSubmitRule>();

  const eligibleLinkablePagesFromObjectKey = getEligibleLinkablePagesFromObjectKey(
    sourceObject.key
  );

  const {
    action: currentSelectedActionType,
    reload_auto: currentSelectedReloadAuto,
    reload_show: currentSelectedReloadShow
  } = watch();

  return (
    <div className="mt-6">
      <div className="mb-4">
        <p className="mb-2 font-medium">
          {t('components.submit_rule_card.edit_modal.action_type')}
        </p>
        <Controller
          name="action"
          render={({ field: { value: selectedActionType, onChange } }) => (
            <Select value={selectedActionType} onValueChange={onChange}>
              <Select.Trigger
                data-testid="submit-rule-action-trigger"
                placeholder={t('actions.select')}
                className={cn('w-full', {
                  'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                    errors?.action
                })}
              />
              <Select.Content>
                {FORM_VIEW_SUBMIT_RULE_ACTION_TYPES.map((actionType) => {
                  // We don't want to show the 'Redirect to the parent page' option if there is no parent page
                  if (actionType === 'parent_page' && !activePage.parentSlug) {
                    return null;
                  }

                  return (
                    <Select.Item
                      key={actionType}
                      value={actionType}
                      data-testid={`${actionType}-submit-rule-item`}
                    >
                      {t(`components.submit_rule_card.actions.${actionType}`)}
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      {currentSelectedActionType === 'message' && (
        <div>
          <div className="mb-2">
            <Label className="font-medium" isRequired>
              {t('components.submit_rule_card.edit_modal.confirmation_message')}
            </Label>
          </div>
          <Controller
            name="message"
            render={({ field: { value: message, onChange } }) => (
              <RichTextEditor
                editorProps={{
                  attributes: {
                    'data-testid': 'submit-rule-message'
                  }
                }}
                intent={errors.message ? 'destructive' : 'default'}
                onUpdate={({ editor }) => {
                  onChange(editor.getText() ? editor.getHTML() : '');
                }}
                content={message || ''}
              />
            )}
          />
          <FormErrorMessage name="message" errors={errors} className="mt-1" />
          {!isActionSubmitRule && (
            <>
              <p className="mt-4 font-medium">{t('components.submit_rule_card.edit_modal.and')}</p>
              <div className="mt-2">
                <div className="flex items-center">
                  <Controller
                    name="reload_show"
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        id={`${submitRule.key}-show-reload`}
                        checked={value}
                        onCheckedChange={(newValue) => {
                          // Toggle the other option off if the current one is being toggled on
                          if (currentSelectedReloadAuto) {
                            setValue('reload_auto', false);
                          }
                          onChange(newValue);
                        }}
                      />
                    )}
                  />
                  <Label htmlFor={`${submitRule.key}-show-reload`} className="ml-2">
                    {t('components.submit_rule_card.additional_actions.reload_show')}
                  </Label>
                </div>
                <div className="mt-1 flex items-center">
                  <Controller
                    name="reload_auto"
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        id={`${submitRule.key}-auto-reload`}
                        checked={value}
                        onCheckedChange={(newValue) => {
                          // Toggle the other option off if the current one is being toggled on
                          if (currentSelectedReloadShow) {
                            setValue('reload_show', false);
                          }
                          onChange(newValue);
                        }}
                      />
                    )}
                  />
                  <Label htmlFor={`${submitRule.key}-auto-reload`} className="ml-2">
                    {t('components.submit_rule_card.additional_actions.reload_auto')}
                  </Label>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {currentSelectedActionType === 'existing_page' && (
        <div>
          <p className="mb-2 font-medium">
            {t('components.submit_rule_card.edit_modal.existing_page')}
          </p>
          <Controller
            name="existing_page"
            render={({ field: { value: existingPage, onChange } }) => (
              <Select value={existingPage} onValueChange={onChange}>
                <Select.Trigger
                  data-testid="existing-page-trigger"
                  placeholder={t('actions.select')}
                  className={cn('w-full', {
                    'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                      errors?.existing_page
                  })}
                />
                <Select.Content>
                  {eligibleLinkablePagesFromObjectKey.map((page) => (
                    <Select.Item key={page.slug} value={page.slug}>
                      {page.label}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            )}
          />
          <FormErrorMessage name="existing_page" errors={errors} className="mt-1" />
        </div>
      )}

      {currentSelectedActionType === 'child_page' &&
        typeof initialSubmitRuleValues.scene === 'string' && (
          <p className="mb-2 text-xs text-subtle">
            <Trans
              i18nKey="components.submit_rule_card.edit_modal.the_user_will_be_redirected_to"
              components={[<span key="0" className="underline" />]}
              values={{
                pageName: pages.find((page) => page.slug === submitRule.scene)?.name
              }}
            />
          </p>
        )}

      {currentSelectedActionType === 'child_page' &&
        ((initialSubmitRuleValues.scene && typeof initialSubmitRuleValues.scene !== 'string') ||
          !initialSubmitRuleValues.scene) && (
          <div>
            <Label htmlFor="submit-rule-child-page" className="font-medium">
              {t('components.submit_rule_card.edit_modal.new_child_page_name')}
            </Label>
            <Input
              id="submit-rule-child-page"
              intent={errors.scene?.root ? 'destructive' : undefined}
              placeholder={t('actions.enter_value')}
              className="mt-2"
              {...register('scene.name')}
            />
            {errors.scene?.root && (
              <p className="mt-1 text-destructive">{errors.scene.root.message}</p>
            )}
          </div>
        )}

      {currentSelectedActionType === 'url' && (
        <div>
          <Label htmlFor="submit-rule-url" className="font-medium">
            {t('components.submit_rule_card.edit_modal.redirect_url')}
          </Label>
          <Input
            id="submit-rule-url"
            intent={errors.url ? 'destructive' : undefined}
            placeholder={t('actions.enter_value')}
            className="mt-2"
            {...register('url')}
          />
          <FormErrorMessage name="url" errors={errors} className="mt-1" />
        </div>
      )}

      {!isActionSubmitRule && (
        <>
          <Divider className="my-6" />
          {submitRule.is_default ? (
            <Banner className="bg-subtle text-default">
              {t('components.submit_rule_card.edit_modal.default_action_description')}
            </Banner>
          ) : (
            <>
              <h3 className="mb-1 font-medium">{t('components.rules.conditions')}</h3>
              <p className="mb-4 text-xs text-subtle">
                {t('components.rules.apply_action_when_conditions_are_met')}
              </p>

              <CriteriaForm
                className="rounded-lg bg-subtle"
                sourceObject={sourceObject}
                criteriaType="submit-rule"
                shouldHaveContent
                canCriteriaValuesBeField={canCriteriaValuesBeField}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
