import { useTranslation } from 'react-i18next';
import { HiArrowRight as NextIcon } from 'react-icons/hi2';
import { Input, Label, Select, Switch, Tabs } from '@knack/asterisk-react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { FieldSelect } from '@/components/FieldSelect';
import { TimeInput } from '@/components/inputs/TimeInput';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { getMeridian, parseTime } from '@/pages/pages/settings-panel/view-settings/calendar/helper';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { getCompatibleFields } from '@/pages/tables/toolkit-sidebar/rules/utils';

export function GeneralSettingsAppearance() {
  const { view, sourceObject } = useActiveViewContext<CalendarView>();
  const { canFieldStoreDateValues } = useFieldHelpers();
  const { fields } = sourceObject;
  const eligibleDateTimeFieldsForCalendarEvent =
    fields.filter((field) => canFieldStoreDateValues(field)) || [];
  const [t] = useTranslation();

  const minTime = parseTime(view.events.time_min || '00:00');
  const minMeridian = getMeridian(view.events.time_min || '00:00');
  const maxTime = parseTime(view.events.time_max || '24:00');
  const maxMeridian = getMeridian(view.events.time_max || '24:00');
  const updateViewSchema = useUpdateView();

  const handleEventField = (val: `field_${string}`) =>
    updateViewSchema({ events: { ...view.events, event_field: { key: val } } });
  const handeLabelField = (val: `field_${string}`) =>
    updateViewSchema({ events: { ...view.events, label_field: { key: val } } });

  const handleDisplay = (value: string) =>
    updateViewSchema({ events: { ...view.events, display_type: value } });
  const handleChangeDisplay = (canChange: boolean) =>
    updateViewSchema({ events: { ...view.events, allow_change_display: canChange } });
  const handleRange = (value: string) =>
    updateViewSchema({ events: { ...view.events, view: value } });
  const handleMinTime = (value: string) =>
    updateViewSchema({ events: { ...view.events, time_min: value } });
  const handleMaxTime = (value: string) =>
    updateViewSchema({ events: { ...view.events, time_max: value } });
  const handleWeekend = (exclude: boolean) =>
    updateViewSchema({ events: { ...view.events, exclude_weekends: !exclude } });
  const handleWeekStart = (weekStart: 'sunday' | 'monday') =>
    updateViewSchema({ events: { ...view.events, week_start: weekStart } });

  const handleTime = ({
    val,
    meridian,
    min
  }: {
    val?: string;
    meridian?: string;
    min: boolean;
  }) => {
    const time = (val || (min ? minTime : maxTime)).split(':').map(Number);
    let hours = time[0];
    const minutes = time[1];
    const timeMeridian = meridian || (min ? minMeridian : maxMeridian);
    if (hours === 12 && timeMeridian === 'AM') {
      hours = 0;
    }
    if (meridian === 'PM' && hours !== 12) {
      hours += 12;
    }

    if (min) {
      handleMinTime([hours, minutes].join(':'));
    } else {
      handleMaxTime([hours, minutes].join(':'));
    }
  };

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t(
        'pages.element_settings.calendar.categories.general_settings.section_titles.appearance'
      )}
    >
      <div className="flex flex-col gap-4">
        <div>
          <Label htmlFor="default-display">
            {t('pages.element_settings.calendar.categories.general_settings.default_display')}
          </Label>
          <Tabs
            value={view.events.display_type}
            onValueChange={handleDisplay}
            className="mt-2"
            id="default-display"
          >
            <Tabs.List
              items={[
                { value: 'calendar', children: t('views.view_names.calendar') },
                { value: 'list', children: t('views.view_names.list') }
              ]}
              shouldDisableResponsive
            />
          </Tabs>
        </div>
        <Label htmlFor="change-display" className="flex items-center gap-2">
          <Switch
            id="change-display"
            checked={view.events.allow_change_display}
            onCheckedChange={handleChangeDisplay}
          />
          {t('pages.element_settings.calendar.categories.general_settings.change_display')}
        </Label>
        <div>
          <Label htmlFor="range">
            {t('pages.element_settings.calendar.categories.general_settings.default_range')}
          </Label>
          <Tabs value={view.events.view} onValueChange={handleRange} id="range">
            <Tabs.List
              items={[
                {
                  value: 'month',
                  children: t('pages.element_settings.report.categories.data_display.sorting.month')
                },
                {
                  value: 'agendaWeek',
                  children: t('pages.element_settings.report.categories.data_display.sorting.week')
                },
                {
                  value: 'agendaDay',
                  children: t('pages.element_settings.report.categories.data_display.sorting.day')
                }
              ]}
              shouldDisableResponsive
            />
          </Tabs>
        </div>
        <div className="flex flex-col gap-1">
          <Label htmlFor="hour-range">
            {t('pages.element_settings.calendar.categories.general_settings.hour_range')}
          </Label>
          <div className="flex items-center gap-2" id="hour-range">
            <TimeInput
              format="HH:MM am"
              onValueChange={(val) => {
                handleTime({ val, min: true });
              }}
              value={minTime}
            >
              <TimeInput.TimeModeSelect
                value={minMeridian}
                onValueChange={(meridian) => {
                  handleTime({ meridian, min: true });
                }}
              />
            </TimeInput>
            <NextIcon />
            <TimeInput
              format="HH:MM am"
              onValueChange={(val) => {
                handleTime({ val, min: false });
              }}
              value={maxTime}
            >
              <TimeInput.TimeModeSelect
                value={maxMeridian}
                onValueChange={(meridian) => {
                  handleTime({ meridian, min: false });
                }}
              />
            </TimeInput>
          </div>
          <Input.Hint>
            {t('pages.element_settings.calendar.categories.general_settings.range_hint')}
          </Input.Hint>
        </div>
        <Label className="flex items-center gap-2" htmlFor="weekends">
          <Switch
            checked={!view.events.exclude_weekends}
            id="weekends"
            onCheckedChange={handleWeekend}
          />
          {t('pages.element_settings.calendar.categories.general_settings.include_weekend')}
        </Label>
        <Label htmlFor="startOfWeek">
          {t('pages.element_settings.calendar.categories.general_settings.week_start')}
          <Select value={view.events.week_start} onValueChange={handleWeekStart}>
            <Select.Container>
              <Select.Trigger
                placeholder={t('actions.select')}
                className="mt-1 w-full text-subtle"
              />
              <Select.Content className="min-w-[230px]">
                <Select.Item value="sunday">
                  {t('pages.element_settings.calendar.categories.general_settings.sunday')}
                </Select.Item>
                <Select.Item value="monday">
                  {t('pages.element_settings.calendar.categories.general_settings.monday')}
                </Select.Item>
              </Select.Content>
            </Select.Container>
          </Select>
        </Label>
        <Label className="flex flex-col gap-2">
          {t('pages.element_settings.calendar.categories.general_settings.date_field')}
          <FieldSelect
            id="general-setting-event-field"
            fields={eligibleDateTimeFieldsForCalendarEvent}
            onFieldChange={handleEventField}
            defaultValue={view.events.event_field.key}
          />
        </Label>
        <Label className="flex flex-col gap-2">
          {t('pages.element_settings.calendar.categories.general_settings.label_field')}
          <FieldSelect
            id="general-setting-label-field"
            fields={getCompatibleFields(fields)}
            onFieldChange={handeLabelField}
            defaultValue={view.events.label_field.key}
          />
        </Label>
      </div>
    </BuilderAccordion.Item>
  );
}
