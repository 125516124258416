import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Label, RadioGroup, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  type BuilderViewFilterConnectionConnectionKey,
  type BuilderViewFilterConnectionSource
} from '@/types/schema/BuilderView';
import { type ConnectionField } from '@/types/schema/fields';
import { type KnackCriteriaWithValueType } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { CriteriaForm } from '@/components/CriteriaForm';
import {
  type FilterConnectionSourceGroup,
  type RestrictedConnectionFormChangeData
} from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/types';
import {
  useConnectionParentOptions,
  type ConnectionParentOption
} from '@/pages/pages/settings-panel/view-settings/helpers/useConnectionParentOptions';

type ConnectionParentValue =
  | 'all'
  | `${ConnectionParentOption['connectionFieldKey']}-${ConnectionParentOption['remoteFieldKey']}-${ConnectionParentOption['type']}`;

interface RestrictedConnectionGroupProps {
  filterConnectionSourceGroup: FilterConnectionSourceGroup;
  onRestrictedConnectionChange: (
    data: RestrictedConnectionFormChangeData,
    fieldKey: KnackFieldKey
  ) => void;
}

export function RestrictedConnectionDialogGroup({
  filterConnectionSourceGroup,
  onRestrictedConnectionChange
}: RestrictedConnectionGroupProps) {
  const [t] = useTranslation('translation', {
    keyPrefix:
      'pages.element_settings.common.categories.data_display.filtering_section.restricted_connections'
  });
  const { getFieldByKey, getBaseFieldOperators } = useFieldHelpers();
  const { getDefaultCriteriaValue, validateCriteriaValues } = useCriteriaHelpers();
  const { getObjectByKey } = useObjectHelpers();

  const connectionField = getFieldByKey(filterConnectionSourceGroup.key) as ConnectionField;
  const connectedObject = connectionField.relationship.object
    ? getObjectByKey(connectionField.relationship.object)
    : null;

  const connectionSource = filterConnectionSourceGroup.source;
  const connectionParentOptions = useConnectionParentOptions({
    field: connectionField
  });

  const [radioValue, setRadioValue] = useState<'connected' | 'all'>(
    connectionSource?.connection_key ? 'connected' : 'all'
  );

  const formattedConnectionForSelectValue: ConnectionParentValue =
    connectionSource?.connection_key && connectionSource?.remote_key && connectionSource?.type
      ? `${connectionSource.connection_key}-${connectionSource.remote_key}-${connectionSource.type}`
      : 'all';

  const criteriaFormSchema = z.object({
    criteria: z.custom<KnackFilter[]>().superRefine((data, context) => {
      if (!connectedObject) {
        return;
      }

      const criteriaValueErrors = validateCriteriaValues(data, connectedObject.fields);

      if (criteriaValueErrors.length) {
        criteriaValueErrors.forEach((error) => {
          context.addIssue({
            path: error.path,
            message: t(error.message || 'errors.value_required'),
            code: 'custom'
          });
        });
      }
    })
  });

  const form = useForm<{ criteria: KnackFilter[]; source: BuilderViewFilterConnectionSource }>({
    resolver: zodResolver(criteriaFormSchema),
    defaultValues: {
      criteria: filterConnectionSourceGroup.filters || [],
      source: connectionSource || { connection_key: '' }
    }
  });

  const formData = useWatch({ control: form.control }) as {
    criteria: KnackFilter[];
    source: BuilderViewFilterConnectionSource;
  };

  const showAllRecordsHelperText =
    connectionParentOptions.length === 1 && formData.criteria.length === 0;
  const shouldDisplayCriteriaForm = formData.criteria.length > 0;
  const shouldDisplayAddFilterButton = formData.criteria.length === 0;

  const formatSourceConnectionValue = (value: ConnectionParentValue) => {
    if (value === 'all') {
      return {
        connection_key: '' as BuilderViewFilterConnectionConnectionKey
      };
    }

    const [connectionKey, remoteKey, type] = value.split('-');

    return {
      connection_key: connectionKey,
      remote_key: remoteKey,
      type
    } as BuilderViewFilterConnectionSource;
  };

  const getDefaultRestrictedConnectionFilter = () => {
    if (!connectedObject || (connectedObject && connectedObject.fields.length === 0)) {
      return null;
    }

    const firstFieldInObject = connectedObject.fields[0];

    const defaultRuleCriteria: KnackCriteriaWithValueType = {
      field: firstFieldInObject.key,
      operator: getBaseFieldOperators(firstFieldInObject)[0],
      value: getDefaultCriteriaValue(firstFieldInObject)
    };

    return defaultRuleCriteria;
  };

  const addNewFilter = () => {
    const defaultFilter = getDefaultRestrictedConnectionFilter();

    if (!defaultFilter) return;

    form.setValue('criteria', [...formData.criteria, defaultFilter]);
  };

  useEffect(() => {
    onRestrictedConnectionChange(formData, connectionField.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  if (!connectedObject) {
    return null;
  }

  const connectedObjectLabel = connectedObject.inflections.singular;

  return (
    <FormProvider {...form}>
      <div className="mt-4">
        <p className="mb-2 font-medium text-emphasis">{connectedObjectLabel}</p>
        {showAllRecordsHelperText && (
          <p className="text-xs text-subtle">
            {t('all_visible_records', {
              connectedObjectName: connectedObjectLabel
            })}
          </p>
        )}
        {connectionParentOptions.length > 1 && (
          <div>
            <RadioGroup
              defaultValue={radioValue}
              onValueChange={(val: 'all' | 'connected') => {
                if (val === 'all') {
                  form.setValue('source', { connection_key: '' });
                }
                setRadioValue(val);
              }}
              className="mb-2"
            >
              <RadioGroup.Container>
                <RadioGroup.Item
                  value="all"
                  id={`${filterConnectionSourceGroup.key}-all-records`}
                />
                <Label htmlFor={`${filterConnectionSourceGroup.key}-all-records`}>
                  {t('all_records')}
                </Label>
              </RadioGroup.Container>
              <RadioGroup.Container>
                <RadioGroup.Item
                  value="connected"
                  id={`${filterConnectionSourceGroup.key}-connected-records`}
                />
                <Label htmlFor={`${filterConnectionSourceGroup.key}-connected-records`}>
                  {t('show_records_connected')}
                </Label>
              </RadioGroup.Container>
            </RadioGroup>
            {radioValue === 'connected' && (
              <div className="ml-6">
                <Select
                  value={formattedConnectionForSelectValue}
                  onValueChange={(val: ConnectionParentValue) =>
                    form.setValue('source', formatSourceConnectionValue(val))
                  }
                >
                  <Select.Trigger className="w-full" />
                  <Select.Content className="min-w-fit">
                    {connectionParentOptions.map((option) => (
                      <Select.Item key={option.value} value={option.value}>
                        {option.label}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              </div>
            )}
          </div>
        )}
        {shouldDisplayCriteriaForm && (
          <div className="mt-2">
            <p className="text-xs text-subtle">
              {t('filter_helper_text', {
                connectedObjectName: connectedObjectLabel
              })}
            </p>
            <div className="mt-2 rounded-lg bg-subtle p-2">
              <CriteriaForm sourceObject={connectedObject} criteriaType="filter" />
            </div>
          </div>
        )}
        {shouldDisplayAddFilterButton && (
          <Button intent="secondary" className="mt-2 gap-1" onClick={addNewFilter}>
            <Button.Icon icon={PlusIcon} />
            {t('add_filter_option')}
          </Button>
        )}
      </div>
    </FormProvider>
  );
}
