import { useId } from 'react';
import { type FieldErrors, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, Input, Label, RadioGroup } from '@knack/asterisk-react';
import { type DateTimePickerProps } from '@knack/asterisk-react/dist/src/components/date-time-picker/DateTimePicker';
import { z } from 'zod';

import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { getDatePickerDateSchema } from '@/components/knack-date-picker/form/DatePickerFormSchemas';

export const getKnackDateEndsSchema = (t: (key: string) => string) =>
  z.union([
    z.object({
      endson: z.literal('never')
    }),
    z.object({
      endson: z.literal('limit'),
      end_count: z.coerce.number().positive()
    }),
    z.object({
      endson: z.literal('date'),
      end_date: getDatePickerDateSchema(t, true) // Server expects the Date in DD/MM/YYYY format
    })
  ]);

export type KnackDateEndsSchema = z.infer<ReturnType<typeof getKnackDateEndsSchema>>;

interface EndsOnProps {
  repeatValue: FieldValues;
  onChange: (value: FieldValues | null) => void;
  errors: FieldErrors;
  format: DateTimePickerProps['format'];
}

export function EndsOn({ repeatValue, onChange, errors, format }: EndsOnProps) {
  const id = useId();
  const [t] = useTranslation();

  if (!repeatValue) return null;

  return (
    <div className="mt-5">
      <Label>{t('date_picker.ends')}</Label>
      <RadioGroup
        value={repeatValue.endson}
        onValueChange={(value) => {
          onChange({
            ...repeatValue,
            endson: value,
            // Set initial value
            end_date: new Date(),
            end_count: 1
          });
        }}
        className="mt-1 flex flex-col"
      >
        <RadioGroup.Container>
          <RadioGroup.Item value="never" id={`${id}radio-item-never`} />
          <Label htmlFor={`${id}radio-item-never`}>{t('date_picker.never')}</Label>
        </RadioGroup.Container>
        <RadioGroup.Container>
          <RadioGroup.Item value="date" id={`${id}radio-item-specific-date`} />
          <Label htmlFor={`${id}radio-item-specific-date`}>
            {t('date_picker.on_specific_date')}
          </Label>
        </RadioGroup.Container>
        {repeatValue.endson === 'date' && (
          <div>
            <DateTimePicker
              format={{
                date: format.date
              }}
              mode="single"
              defaultValue={repeatValue.end_date}
              onChange={(endDate) => {
                onChange({
                  ...repeatValue,
                  end_date: endDate
                });
              }}
            />
            <FormErrorMessage className="mt-1" errors={errors} name="repeat.end_date" />
          </div>
        )}
        <RadioGroup.Container>
          <RadioGroup.Item value="limit" id={`${id}radio-item-after-occurrences`} />
          <Label htmlFor={`${id}radio-item-after-occurrences`}>
            {t('date_picker.after_occurrences')}
          </Label>
        </RadioGroup.Container>
        {repeatValue.endson === 'limit' && (
          <>
            <div className="flex items-center gap-2">
              <Input
                type="number"
                value={repeatValue.end_count}
                onChange={(event) => {
                  onChange({
                    ...repeatValue,
                    end_count: event.target.value
                  });
                }}
                className="max-w-20"
              />
              <p>{t('date_picker.occurrence')}</p>
            </div>
            <FormErrorMessage className="mt-1" errors={errors} name="repeat.end_count" />
          </>
        )}
      </RadioGroup>
    </div>
  );
}
