import { type BuilderViewSourceSchema } from '@/types/schema/BuilderView';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateDetailsViewInputs } from './detailsViewSchema';
import { generateFormViewInputs } from './formViewSchema';
import { generateNewViewKey } from './generateNewViewKey';

export const DEFAULT_COLOR_CRITERIA = '#3366cc';

const baseCalendarViewSchema: Partial<CalendarView> = {
  type: 'calendar',
  reportType: null,
  description: '',
  events: {
    show_details: true,
    allow_add: true,
    allow_edit: true,
    allow_multiple_per_slot: true,
    allow_all_day: true,
    view: 'agendaWeek',
    display_type: 'calendar',
    event_field: {
      key: undefined
    },
    label_field: {
      key: undefined
    },
    time_max: '24:00',
    time_min: '00:00',
    exclude_weekends: false,
    week_start: 'sunday',
    allow_delete: false,
    rss: false,
    ical: false,
    allow_change_display: false,
    event_color_default: DEFAULT_COLOR_CRITERIA,
    event_colors: []
  }
};

export function getCalendarViewSchema(
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema,
  selectedEventKey?: KnackFieldKey
) {
  const objectName =
    viewSource.recordDisplayQuantity === 'many'
      ? viewSource.object.inflections.plural
      : viewSource.object.inflections.singular;

  const eventKey =
    selectedEventKey || viewSource.object.fields.find((field) => field.type === 'date_time')?.key;
  let labelField: KnackField | undefined = viewSource.object.fields.find(
    (field) => field.type === 'short_text'
  );

  if (!labelField) {
    [labelField] = viewSource.object.fields;
  }
  const { key: labelKey } = labelField;

  const detailsColumn = generateDetailsViewInputs({ viewSourceObject: viewSource.object });

  const formInputs = generateFormViewInputs({
    viewSourceObject: viewSource.object,
    viewSourceSchema
  });

  const calendarViewSchema: Partial<CalendarView> = {
    ...baseCalendarViewSchema,
    key: generateNewViewKey(),
    type: 'calendar',
    name: objectName,
    title: objectName,
    description: '',
    source: {
      ...viewSourceSchema,
      sort: [
        ...(viewSourceSchema.sort || []),
        {
          field: detailsColumn[0].key,
          order: 'asc'
        }
      ]
    },
    events: {
      ...baseCalendarViewSchema.events!,
      event_field: {
        key: eventKey
      },
      label_field: {
        key: labelKey
      }
    },
    details: {
      columns: [
        {
          groups: [
            {
              columns: [detailsColumn]
            }
          ],
          width: 100
        }
      ],
      list_layout: 'one-column',
      label_format: 'left',
      layout: 'full'
    },
    form: {
      groups: [
        {
          columns: [
            {
              inputs: formInputs
            }
          ]
        }
      ]
    }
  };

  return calendarViewSchema;
}
