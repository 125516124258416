import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Label, Tabs, type TabsListItem } from '@knack/asterisk-react';

import { type TableViewColumn } from '@/types/schema/views/TableView';
import { getNormalizedIconName } from '@/utils/font-awesome';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { KnackFontAwesomeIcon } from '@/components/font-awesome/KnackFontAwesomeIcon';
import { SelectIconModal } from '@/components/SelectIconModal';

export function TableColumnSettingsActionButtonSection({
  columnItem,
  updateViewColumn
}: {
  columnItem: TableViewColumn;
  updateViewColumn: (column: Partial<TableViewColumn>) => void;
}) {
  const [t] = useTranslation();
  const [isSelectIconModalOpen, setIsSelectIconModalOpen] = useState(false);
  // TODO: This is a new feature that is not yet implemented. We need to wait for the design and product team to provide final thoughts.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shouldRenderOpenLinkSwitch = columnItem.type === 'link';

  const defaultIconAlignment = 'left';
  const alignItems: TabsListItem[] = [
    {
      children: t('keywords.left'),
      value: 'left'
    },
    {
      children: t('keywords.right'),
      value: 'right'
    }
  ];

  const getButtonSectionLabel = () => {
    if (columnItem.type === 'delete') {
      return t(
        'pages.element_settings.common.categories.data_display.field_management.delete_button'
      );
    }
    return t('pages.element_settings.common.categories.data_display.field_management.link_button');
  };

  return (
    <BuilderAccordion.Item label={getButtonSectionLabel()} isDefaultOpen>
      <div>
        <Label>
          {t('pages.element_settings.common.categories.data_display.field_management.button_text')}
          <Input
            placeholder={t('actions.delete')}
            className="mt-2"
            value={columnItem.link_text}
            onChange={(e) => updateViewColumn({ id: columnItem.id, link_text: e.target.value })}
          />
        </Label>
      </div>
      <div className="mt-4">
        <p>{t('keywords.icon')}</p>
        <div className="mt-2 flex">
          {columnItem.icon?.icon ? (
            <>
              <KnackFontAwesomeIcon
                iconName={getNormalizedIconName(columnItem.icon?.icon)}
                size="xl"
                asButton
                onClick={() => setIsSelectIconModalOpen(true)}
              />
              <Button
                intent="secondary"
                className="ml-2"
                aria-label={t('components.icons.remove_icon')}
                onClick={() => {
                  updateViewColumn({ id: columnItem.id, icon: null });
                }}
              >
                {t('components.icons.remove_icon')}
              </Button>
            </>
          ) : (
            <Button
              intent="secondary"
              aria-label={t('components.icons.select_icon')}
              onClick={() => setIsSelectIconModalOpen(true)}
            >
              {t('components.icons.select_icon')}
            </Button>
          )}
        </div>
        <div className="mt-2 flex items-center gap-2">
          <Label>{t('components.icons.icon_position')}</Label>
          <Tabs
            defaultValue={columnItem.icon?.align || defaultIconAlignment}
            onValueChange={(value) =>
              updateViewColumn({
                id: columnItem.id,
                icon: {
                  align: value as 'left' | 'right',
                  icon: columnItem.icon ? columnItem.icon?.icon : defaultIconAlignment
                }
              })
            }
          >
            <Tabs.List items={alignItems} shouldDisableResponsive />
          </Tabs>
        </div>
        {isSelectIconModalOpen && (
          <SelectIconModal
            preSelectedIcon={columnItem.icon?.icon}
            onClose={() => setIsSelectIconModalOpen(false)}
            onSubmit={(newSelectedIconName) => {
              if (newSelectedIconName) {
                updateViewColumn({
                  id: columnItem.id,
                  icon: {
                    align: columnItem.icon?.align ?? defaultIconAlignment,
                    icon: newSelectedIconName
                  }
                });
              }
              setIsSelectIconModalOpen(false);
            }}
          />
        )}
      </div>
      {/* {shouldRenderOpenLinkSwitch && <ColumnSettingsOpenLinkTabs />} */}
    </BuilderAccordion.Item>
  );
}
