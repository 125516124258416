import { BuilderAccordion } from '@/components/BuilderAccordion';

export function DataDisplaySettings() {
  return (
    <BuilderAccordion>
      <BuilderAccordion.Item isDefaultOpen label="Coming Soon">
        &nbsp;
      </BuilderAccordion.Item>
    </BuilderAccordion>
  );
}
