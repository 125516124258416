import { useEffect, useState } from 'react';
import {
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon
} from 'react-icons/hi2';
import { t } from 'i18next';

import { type TableView } from '@/types/schema/views/TableView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { CommonSourceBanner } from '@/pages/pages/settings-panel/view-settings/common/CommonSourceBanner';
import { TableActionsSettingsCategory } from '@/pages/pages/settings-panel/view-settings/table/actions/TableActionsSettingsCategory';
import { TableViewColumnSettings } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/TableViewColumnSettings';
import { TableDataDisplaySettingsCategory } from '@/pages/pages/settings-panel/view-settings/table/TableDataDisplaySettingsCategory';
import { TableGeneralSettingsCategory } from '@/pages/pages/settings-panel/view-settings/table/TableGeneralSettingsCategory';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

type TableSettingsCategoryId =
  | 'general-settings'
  | 'data-display'
  | 'actions'
  | 'styling'
  | 'column-settings';
type TableSettingsCategoryItem = ViewSettingsCategoryItem<TableSettingsCategoryId>;

export function TableViewSettings() {
  const { view } = useActiveViewContext<TableView>();
  const [activeCategoryId, setActiveCategoryId] = useState<TableSettingsCategoryId | null>(null);

  function getPanelTitle(categories: TableSettingsCategoryItem[]) {
    const activeCategory = activeCategoryId
      ? categories.find((category) => category.id === activeCategoryId)
      : null;

    return activeCategory ? activeCategory.name : t('pages.element_settings.table.heading');
  }

  function getTableSettingsCategories() {
    const TABLE_SETTINGS_CATEGORIES: TableSettingsCategoryItem[] = [
      {
        id: 'general-settings',
        name: t('pages.element_settings.common.categories.general_settings.name'),
        description: t('pages.element_settings.table.categories.general_settings.description'),
        icon: GeneralSettingsIcon
      },
      {
        id: 'data-display',
        name: t('pages.element_settings.common.categories.data_display.name'),
        description: t('pages.element_settings.common.categories.data_display.description'),
        icon: FieldsIcon
      },
      {
        id: 'actions',
        name: t('pages.element_settings.table.categories.actions.name'),
        description: t('pages.element_settings.table.categories.actions.description'),
        icon: ColumnsIcon
      },
      {
        id: 'column-settings',
        name: t(
          'pages.element_settings.table.categories.data_display.general_settings.column_properties'
        ),
        description: '',
        icon: FieldsIcon
      }
    ];

    return TABLE_SETTINGS_CATEGORIES;
  }

  const tableSettingsCategories = getTableSettingsCategories();
  const panelTitle = getPanelTitle(tableSettingsCategories);

  const { isViewInputEditMode, setIsViewInputEditMode } = useViewInputEditModeContext();

  const handleOnBack = () => {
    if (!activeCategoryId) return;

    if (activeCategoryId === 'column-settings') {
      setIsViewInputEditMode(false);
      setActiveCategoryId('data-display');
      return;
    }

    setActiveCategoryId(null);
  };

  useEffect(() => {
    if (isViewInputEditMode && activeCategoryId !== 'column-settings') {
      setActiveCategoryId('column-settings');
    }
    if (!isViewInputEditMode && activeCategoryId === 'column-settings') {
      setActiveCategoryId('data-display');
    }
  }, [activeCategoryId, isViewInputEditMode]);

  return (
    <CollapsiblePanel.Content
      title={panelTitle}
      onBack={activeCategoryId ? () => handleOnBack() : undefined}
      shouldHideDivider={
        activeCategoryId === 'data-display' || activeCategoryId === 'column-settings'
      }
    >
      {activeCategoryId ? (
        <>
          {activeCategoryId === 'general-settings' && <TableGeneralSettingsCategory />}
          {activeCategoryId === 'data-display' && <TableDataDisplaySettingsCategory />}
          {activeCategoryId === 'actions' && <TableActionsSettingsCategory />}
          {activeCategoryId === 'column-settings' && <TableViewColumnSettings />}
        </>
      ) : (
        <>
          <CommonSourceBanner view={view} />
          <ViewSettingsCategoryList
            categories={tableSettingsCategories.filter(
              (category) => category.id !== 'column-settings'
            )}
            onCategorySelect={(selectedCategoryId: string) => {
              if (selectedCategoryId !== 'column-settings' && isViewInputEditMode) {
                setIsViewInputEditMode(false);
              }
              setActiveCategoryId(selectedCategoryId as TableSettingsCategoryId);
            }}
          />
        </>
      )}
    </CollapsiblePanel.Content>
  );
}
