import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { type DataTableFilter } from '@/components/data-table/display/header/FilteringSection';
import { MatchSelector } from '@/components/data-table/display/header/filters/MatchSelector';
import { OperatorSelect } from '@/components/data-table/display/header/filters/OperatorSelect';
import { useCurrentTable } from '@/components/data-table/helpers/useCurrentTable';
import { FieldSelect } from '@/components/FieldSelect';
import { FieldCriteriaValueInput } from '@/components/inputs/FieldCriteriaValueInput';

interface FiltersProps {
  filters: DataTableFilter | null;
  onApplyFilter: (format: DataTableFilter | null) => void;
  onCancel: () => void;
}

export interface DataTableFiltersFormData {
  match: 'and' | 'or';
  criteria: KnackCriteria[];
}

export function Filters({ filters, onApplyFilter, onCancel }: FiltersProps) {
  const [t] = useTranslation();
  const sourceObject = useCurrentTable();

  if (!sourceObject) {
    throw new Error('Source object is required');
  }

  const { fields } = sourceObject;

  const {
    getDefaultCriteriaValue,
    getDefaultCriteriaOperator,
    shouldResetCriteriaValue,
    shouldResetCriteriaOperator
  } = useCriteriaHelpers();

  const filterSchema = z.custom<DataTableFiltersFormData>();

  const defaultFilterRule = {
    field: fields[0].key,
    operator: getDefaultCriteriaOperator(fields[0], 'filter'),
    value: getDefaultCriteriaValue(fields[0])
  };

  const defaultFilterFormValue: DataTableFiltersFormData = filters
    ? { ...filters, criteria: filters.rules }
    : { match: 'and', criteria: [defaultFilterRule] };

  const form = useForm<DataTableFiltersFormData>({
    resolver: zodResolver(filterSchema),
    defaultValues: defaultFilterFormValue
  });

  const {
    fields: filterRules,
    append: addNewFilterRule,
    remove: removeFilterRule
  } = useFieldArray({
    control: form.control,
    name: 'criteria'
  });

  const watchFilterRulesArray = form.watch('criteria');

  const controlledCriteriaFormFields = filterRules.map((field, index) => ({
    ...field,
    ...watchFilterRulesArray[index]
  }));

  const onFormSubmit = (data: DataTableFiltersFormData) => {
    const { criteria, ...restData } = data;
    const shouldResetFilters = criteria.length === 0;

    if (shouldResetFilters) {
      onApplyFilter(null);
      return;
    }

    onApplyFilter({ ...restData, rules: criteria });
  };

  const onCriteriaFieldChange = ({
    criteriaIndex,
    newFieldKey
  }: {
    criteriaIndex: number;
    newFieldKey: KnackFieldKey;
  }) => {
    let previousField: KnackField | null = null;
    let newField: KnackField | null = null;

    const criteriaToEdit = form.getValues(`criteria.${criteriaIndex}`);

    fields.forEach((field) => {
      if (field.key === criteriaToEdit.field) {
        previousField = field;
      } else if (field.key === newFieldKey) {
        newField = field;
      }
    });

    if (!newField) {
      return;
    }

    const newCriteria = {
      ...criteriaToEdit,
      field: newFieldKey,
      operator: shouldResetCriteriaOperator(newField, criteriaToEdit.operator, 'filter')
        ? getDefaultCriteriaOperator(newField, 'filter')
        : criteriaToEdit.operator,
      value:
        !previousField || shouldResetCriteriaValue(newField, previousField)
          ? getDefaultCriteriaValue(newField)
          : criteriaToEdit.value
    };

    form.setValue(`criteria.${criteriaIndex}`, newCriteria);

    form.clearErrors();
  };

  return (
    <FormProvider {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(onFormSubmit)}>
        {controlledCriteriaFormFields.map((criteriaFormField, criteriaFormFieldIndex) => {
          const selectedField = fields.find((field) => field.key === criteriaFormField.field);
          const shouldHideValue = shouldHideValueBasedOnOperator(criteriaFormField.operator);

          if (!selectedField) return null;

          return (
            <div key={criteriaFormField.id} className="flex w-full items-start gap-2">
              <MatchSelector ruleIndex={criteriaFormFieldIndex} />
              <div className="mb-4 flex w-full flex-wrap items-center gap-2">
                <div className="flex w-full items-center gap-2">
                  <div className="flex-1">
                    <Controller
                      control={form.control}
                      name={`criteria.${criteriaFormFieldIndex}.field`}
                      render={({ field }) => (
                        <FieldSelect
                          id="field-select"
                          defaultValue={field.value}
                          data-testid={`filter-rule-${criteriaFormFieldIndex}-field-select`}
                          fields={fields}
                          onFieldChange={(newFieldKey) => {
                            onCriteriaFieldChange({
                              criteriaIndex: criteriaFormFieldIndex,
                              newFieldKey
                            });
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="flex-1">
                    <OperatorSelect field={selectedField} ruleIndex={criteriaFormFieldIndex} />
                  </div>
                </div>
                {!shouldHideValue && (
                  <div className="w-full">
                    <FieldCriteriaValueInput
                      selectedField={selectedField}
                      selectedOperator={criteriaFormField.operator}
                      index={criteriaFormFieldIndex}
                    />
                  </div>
                )}
              </div>
              <Button
                intent="minimal"
                size="xs"
                data-testid={`filter-rule-${criteriaFormFieldIndex}-remove`}
                onClick={() => removeFilterRule(criteriaFormFieldIndex)}
                className="mt-1"
              >
                <CloseIcon size={16} />
              </Button>
            </div>
          );
        })}
        <Button
          data-testid="filter-add-condition"
          intent="secondary"
          className="ml-[88px]"
          onClick={() => {
            addNewFilterRule(defaultFilterRule);
          }}
        >
          <PlusIcon className="mr-2" /> {t('components.data_table.filtering.condition')}
        </Button>

        <div className="mt-4 flex justify-between gap-2">
          <Button
            data-testid="filter-remove"
            intent="minimal"
            onClick={() => {
              onApplyFilter(null);
              onCancel();
            }}
          >
            {t('components.data_table.filtering.remove')}
          </Button>
          <div className="flex gap-2">
            <Button data-testid="filter-cancel" intent="minimal" onClick={onCancel}>
              {t('components.data_table.filtering.cancel')}
            </Button>
            <Button data-testid="filter-apply" type="submit">
              {t('actions.apply')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
