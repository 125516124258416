import { DateTime } from 'luxon';

import { type TimerField } from '@/types/schema/fields';
import {
  defaultDateFormatMap,
  type TimeValues
} from '@/components/import/confirm-import/date-time/types';

export const getRawTime = (time: TimeValues, format: 'HH:MM am' | 'HH MM (military)') => {
  const hours =
    Number(time.hours) < 12 && time.am_pm === 'PM' && format === 'HH MM (military)'
      ? (Number(time.hours) + 12).toString()
      : time.hours;
  return `${hours}:${time.minutes}`;
};

export const getDefaultTimerTime = (
  inputFormat: TimerField['format'],
  additionalHours?: number
) => {
  const is12HourFormat = inputFormat.time_format === 'HH:MM am';
  const fullTimeFormat = is12HourFormat ? 'hh:mm' : 'HH:mm';
  const defaultTime = additionalHours
    ? DateTime.now().plus({ hours: additionalHours })
    : DateTime.now();

  const timePeriodFormat: 'AM' | 'PM' = defaultTime.hour < 12 ? 'AM' : 'PM';

  return {
    date: defaultTime.toFormat(defaultDateFormatMap[inputFormat.date_format || 'mm/dd/yyyy']),
    hours: Number(is12HourFormat ? defaultTime.toFormat('hh') : defaultTime.toFormat('HH')),
    minutes: Number(defaultTime.minute < 10 ? `0${defaultTime.minute}` : defaultTime.minute),
    am_pm: timePeriodFormat,
    rawTime: defaultTime.toFormat(fullTimeFormat),
    iso_timestamp: defaultTime.toISO({ includeOffset: false })
  };
};

export const getDefaultTimerData = (format: TimerField['format']) => ({
  times: [
    {
      from: getDefaultTimerTime(format),
      to: getDefaultTimerTime(format, 1)
    }
  ]
});
