import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToast } from '@knack/asterisk-react';
import { type WorkBook } from 'xlsx';
import * as XLSX from 'xlsx';

import { useGoogleSheetsPreviewMutation } from '@/hooks/api/mutations/useGoogleSheetsPreviewMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useApplication } from '@/hooks/useApplication';
import { getBuilderBaseUrl } from '@/utils/application';
import {
  importDataAllowedFileFormats,
  SPREADSHEET_MAX_FILE_SIZE_IN_BYTES,
  spreadsheetUploadErrorMapping
} from '@/utils/constants';
import { isFlagEnabled } from '@/utils/flagsmith';
import { safeLogRocketTrack } from '@/utils/logrocket';
import { readFileAsync } from '@/utils/readFileAsync';
import { getValidTablesForImport } from '@/utils/tables';
import { FileDropzone } from '@/components/FileDropzone';
import { FullPageSpinner } from '@/components/FullPageSpinner';
import { useImportStore } from '@/components/import/useImportStore';
import { transformGoogleSheetsDataToWorkbook } from '@/components/import/utils';
import { WizardLayout } from '@/components/layout/WizardLayout';
import { AddTableSourceItem } from '@/pages/add-table/AddTableSourceItem';
import { DisclosureLinks } from '@/pages/add-table/DisclosureLinks';
import { useGooglePickerApi } from '@/pages/add-table/useGooglePickerApi';
import GoogleSheetsIcon from '@/assets/svg/google-sheets.svg';
import { ROUTES } from '@/Router';
import { AddIntoExistingTable } from './AddIntoExistingTable';

export function ImportRecordsPage() {
  const app = useApplication();
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { data: application } = useApplicationQuery();
  const navigate = useNavigate();
  const builderBaseUrl = getBuilderBaseUrl();
  const [searchParams] = useSearchParams();

  const [selectedFile, setSelectedFile] = useState<File | google.picker.DocumentObject | undefined>(
    undefined
  );
  const [selectedWorkbook, setSelectedWorkbook] = useState<WorkBook | undefined>(undefined);
  const [googleAccessToken, setGoogleAccessToken] = useState<string | undefined>(undefined);
  const [googleSheetRowCountMap, setGoogleSheetRowCountMap] = useState<Record<string, number>>({});
  const [isGooglePickerLoading, setIsGooglePickerLoading] = useState(false);
  const { mutate: fetchGoogleSheetsPreview } = useGoogleSheetsPreviewMutation();
  const [isFetchingGoogleSheetsPreviewError, setIsFetchingGoogleSheetsPreviewError] =
    useState(false);
  const { existingTable, setExistingTable } = useImportStore((state) => ({
    existingTable: state.existingTable,
    setExistingTable: state.setExistingTable
  }));
  const urlParams = useParams<{ id: string }>();
  const { id: objectKey } = urlParams;

  const onBack = () => {
    setSelectedFile(undefined);
  };

  const pickerCallback = (data: google.picker.ResponseObject, token: string) => {
    if (
      data?.[google.picker.Response.ACTION] === google.picker.Action.PICKED &&
      Array.isArray(data?.[google.picker.Response.DOCUMENTS]) &&
      data[google.picker.Response.DOCUMENTS].length > 0
    ) {
      const doc = data[google.picker.Response.DOCUMENTS][0];
      setIsGooglePickerLoading(true);

      fetchGoogleSheetsPreview(
        { spreadsheetId: doc[google.picker.Document.ID], token },
        {
          onSuccess: (response) => {
            const workbook = transformGoogleSheetsDataToWorkbook(response);

            const sheetRowCounts = response.reduce(
              (acc, sheet) => {
                acc[sheet.name] = sheet.rowCount;
                return acc;
              },
              {} as Record<string, number>
            );

            setGoogleSheetRowCountMap(sheetRowCounts);
            setSelectedFile(doc);
            setSelectedWorkbook(workbook);
            setGoogleAccessToken(token);
            setIsGooglePickerLoading(false);
            setIsFetchingGoogleSheetsPreviewError(false);
          },
          onError: () => {
            setIsFetchingGoogleSheetsPreviewError(true);
            setIsGooglePickerLoading(false);
          }
        }
      );
    } else {
      // Handle case when no document is selected or data is invalid
      console.warn('Google Picker: No document selected or invalid data received.');
      setIsFetchingGoogleSheetsPreviewError(true);
    }
  };

  const initializeGooglePicker = useGooglePickerApi(pickerCallback);

  if (!objectKey) {
    throw new Error('No object key provided');
  }

  const googleSheetsItem = {
    id: 'google-sheets',
    title: t('components.add_table.google_sheets'),
    icon: GoogleSheetsIcon
  };

  const handleSourceListItemClick = (id: string) => {
    if (id === 'google-sheets') {
      initializeGooglePicker();
    }
  };

  const handleOnClose = useCallback(() => {
    const shouldRedirectToNewBuilder =
      (searchParams.has('origin') && searchParams.get('origin') === 'builder-next') ||
      isFlagEnabled('full_nextgen_access');
    safeLogRocketTrack('NavigateToBuilder', application?.account.isHipaa);

    const oldBuilderPath = `${builderBaseUrl}/records/objects/${existingTable?.key}`;

    const newBuilderPath = `${generatePath(ROUTES.TABLES_ID, {
      id: existingTable?.key || ''
    })}`;

    if (shouldRedirectToNewBuilder) {
      navigate(newBuilderPath);
    } else {
      window.location.replace(oldBuilderPath);
    }
  }, [application?.account.isHipaa, builderBaseUrl, existingTable?.key, navigate, searchParams]);

  useEffect(() => {
    if (isFetchingGoogleSheetsPreviewError) {
      presentToast({
        title: t('components.add_table.errors.google_sheets_import')
      });
    }
  }, [isFetchingGoogleSheetsPreviewError, presentToast, t]);

  useEffect(() => {
    if (existingTable) return;

    const tables = getValidTablesForImport(app);
    const foundTable = tables.find((obj) => obj?.key === objectKey);

    if (!foundTable) {
      throw new Error('Selected table does not exist');
    }

    setExistingTable(foundTable);
  }, [app, existingTable, objectKey, setExistingTable]);

  if (isGooglePickerLoading) {
    return <FullPageSpinner />;
  }

  if (!selectedFile) {
    return (
      <WizardLayout
        title={t('components.add_into_existing_table.title', {
          table_name: existingTable?.name ?? ''
        })}
        dataTestid="import-records-page-wizard-layout"
        onClose={handleOnClose}
      >
        <FileDropzone
          onDrop={async (files: File[]) => {
            const file = files[0];
            const arrayBuffer = await readFileAsync(file, () => {});

            const workbook = XLSX.read(arrayBuffer, {
              cellDates: true,
              cellNF: true,
              dense: true
            });
            setSelectedFile(file);
            setSelectedWorkbook(workbook);
          }}
          className="mt-7"
          allowedFormats={importDataAllowedFileFormats}
          description={t('components.file_dropzone.description')}
          maxFileSize={SPREADSHEET_MAX_FILE_SIZE_IN_BYTES}
          errorMapping={(error) => spreadsheetUploadErrorMapping(error, t)}
          data-testid="import-records-file-dropzone"
        />

        <div className="p-4 text-center">{t('components.add_into_existing_table.or')}</div>
        <AddTableSourceItem
          item={googleSheetsItem}
          onClick={handleSourceListItemClick}
          isDisabled={isGooglePickerLoading}
        />
        <DisclosureLinks className="m-5" />
      </WizardLayout>
    );
  }

  return (
    <AddIntoExistingTable
      onBack={onBack}
      file={selectedFile}
      workbook={selectedWorkbook}
      token={googleAccessToken}
      googleSheetRowCountMap={googleSheetRowCountMap}
    />
  );
}
