import { type ConnectionFieldFormat, type DateTimeFieldFormat } from '@/types/schema/fields';
import {
  type KnackField,
  type KnackFieldKey,
  type KnackFieldType
} from '@/types/schema/KnackField';

export type RawData = (string | undefined)[];

export type HeaderColumn = {
  name: string;
  type: string;
};

export type Column = {
  header: string;
  accessorKey: string;
  meta: ColumnMeta;
};

export type HeaderRowData = HeaderColumn[];

export type Counters = { [key: string]: number }[];

export type PredictedFieldFormat = string | DateTimeFieldFormat | ConnectionFieldFormat;

export type SerializedNewColumn = {
  name: string;
  index: number;
  added: boolean;
  addedFieldType?: KnackFieldType;
  parts: FieldPart[];
  predictedType?: KnackFieldType;
  predictedFormat?: PredictedFieldFormat;
};

export type SerializedMappedColumn = {
  complex?: boolean;
  key: string;
  mapped: boolean;
  mappedColumnIndex: number | undefined;
  matchable: boolean;
  name: string;
  type?: KnackFieldType;
  format?: PredictedFieldFormat;
};

export interface ColumnMeta {
  newFieldType?: KnackFieldType;
  parts: FieldPart[];
  existingKnackField?: KnackField;
  newFieldFormat?: PredictedFieldFormat;
  isThisColumnMapped: boolean;
  mappedColumnIndex?: string;
  connectionMatchField?: string;
  connectionObjectKey?: string;
  connectionNoMatchRule?: 'skip' | 'insert';
}

export type FieldPart = {
  mapped: boolean;
  mappedColumnIndex?: number;
  part: {
    key: string;
    label: string;
  };
};

export interface MatchRule {
  ruleId: number;
  columnIndex: number;
  fieldKey?: string;
}

export enum ImportStep {
  SelectFile = 1,
  FieldMapping = 2,
  AdvancedOptions = 3,
  ConfirmImport = 4
}

export interface Spreadsheet {
  id: string;
  name: string;
}

export interface DefaultValue {
  field: KnackFieldKey;
  type: string;
  input?: string;
  connection_field?: string;
  value: string | number | boolean | string[] | Record<string, unknown>;
}
