import { useTranslation } from 'react-i18next';
import { Badge, Divider, Input, Label, Switch } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { cn } from '@/utils/tailwind';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { FiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/common/FiltersGroupCard';
import { TableDisplayRules } from '@/pages/pages/settings-panel/view-settings/table/data-display/display-rules/TableDisplayRules';
import { FieldFormattingSettings } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/FieldFormattingSettings';
import { TableColumnSettingsActionButtonSection } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/TableColumnSettingsActionButtonSection';
import { TableViewColumnSettingsConnection } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/TableViewColumnSettingsConnection';
import { TableViewColumnSettingsFiltersDialog } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/TableViewColumnSettingsFiltersDialog';
import { TruncateTextSettings } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/TruncateTextSettings';
import { ViewColumnDynamicActions } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/ViewColumnDynamicActions';
import { TableRecordRules } from '@/pages/pages/settings-panel/view-settings/table/data-display/record-rules/TableRecordRules';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';

const fieldsTypesWithoutInLineEditing: KnackFieldType[] = [
  'auto_increment',
  'count',
  'sum',
  'max',
  'min',
  'average',
  'concatenation'
];

const fieldTypesAllowedForSummary: KnackFieldType[] = [
  'number',
  'currency',
  'min',
  'max',
  'sum',
  'average',
  'count',
  'rating'
];

export function TableViewColumnSettings() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<TableView>();
  const { viewInputId, setIsViewInputEditMode } = useViewInputEditModeContext();
  const updateViewSchema = useUpdateView<TableView>();

  const columnItem = view.columns.find((col) => col.id === viewInputId);

  if (!columnItem) {
    setIsViewInputEditMode(false);
    return null;
  }

  const columnField = sourceObject.fields.find(
    (field) => field.key === columnItem?.field?.key || field.key === columnItem.connection?.key
  );

  const isActionColumn = columnItem.type !== 'field';
  const isConnectedFieldFromOtherSourceObject = !!columnItem.connection?.key;
  const shouldRenderConnectionSettings = columnField?.type === 'connection';

  const shouldRenderInlineEditingOption =
    columnField &&
    !fieldsTypesWithoutInLineEditing.includes(columnField?.type) &&
    view.options?.cell_editor &&
    !isConnectedFieldFromOtherSourceObject;
  const shouldRenderAllowColumnSummariesOption =
    columnField &&
    fieldTypesAllowedForSummary.includes(columnField?.type) &&
    view.totals.length > 0;

  const shouldRenderTruncateTextOption = columnField?.type === 'paragraph_text';
  const shouldRenderGrouping = !isActionColumn;

  const shouldDisableRecordRules = columnItem.ignore_edit || !shouldRenderInlineEditingOption;

  const shouldRenderRecordRulesSection = !isActionColumn && !isConnectedFieldFromOtherSourceObject;

  const shouldRenderActionButtonSection = isActionColumn && columnItem.type !== 'action_link';
  const shouldRenderDynamicActions = isActionColumn && columnItem.type === 'action_link';

  const updateViewColumn = (updatedData: Partial<TableViewColumn>) => {
    const newColumns = view.columns.map((col) => {
      if (col.id === updatedData.id) {
        return {
          ...col,
          ...updatedData
        };
      }

      return col;
    });

    updateViewSchema({
      columns: newColumns
    });
  };

  return (
    <div>
      <Divider className="mb-4" />
      <BuilderAccordion>
        <BuilderAccordion.Item
          label={t(
            'pages.element_settings.common.categories.data_display.field_management.column_label'
          )}
          isDefaultOpen
        >
          <Input
            value={columnItem.header}
            onChange={(e) => updateViewColumn({ id: columnItem.id, header: e.target.value })}
          />
        </BuilderAccordion.Item>
        {shouldRenderDynamicActions && (
          <ViewColumnDynamicActions<TableViewColumn>
            columnItem={columnItem}
            updateViewColumn={updateViewColumn}
          />
        )}
        {shouldRenderTruncateTextOption && (
          <TruncateTextSettings columnItem={columnItem} updateViewColumn={updateViewColumn} />
        )}
        {shouldRenderAllowColumnSummariesOption && (
          <Label className="flex items-center gap-2">
            <Switch
              checked={!columnItem.ignore_summary}
              onCheckedChange={(val) =>
                updateViewColumn({ id: columnItem.id, ignore_summary: !val })
              }
            />
            {t(
              'pages.element_settings.table.categories.data_display.field_management.allow_column_summaries'
            )}
          </Label>
        )}
        {shouldRenderInlineEditingOption && (
          <BuilderAccordion.Item
            label={t(
              'pages.element_settings.table.categories.data_display.field_management.inline_editing_option'
            )}
            isDefaultOpen
          >
            <Label className="flex items-center gap-2">
              <Switch
                checked={!columnItem.ignore_edit}
                onCheckedChange={(val) =>
                  updateViewColumn({ id: columnItem.id, ignore_edit: !val })
                }
              />
              {t(
                'pages.element_settings.table.categories.data_display.field_management.allow_inline_editing'
              )}
            </Label>
            {columnField.type === 'connection' && !columnItem.ignore_edit && (
              <div className="mt-4 space-y-2">
                {columnItem.source && columnItem.source.filters.length > 0 && (
                  <FiltersGroupsCard filtersCriteria={columnItem.source.filters} />
                )}
                <TableViewColumnSettingsFiltersDialog
                  column={columnItem}
                  field={columnField}
                  updateViewColumn={updateViewColumn}
                />
              </div>
            )}
          </BuilderAccordion.Item>
        )}
        {shouldRenderRecordRulesSection && (
          <BuilderAccordion.Item
            label={t('components.rules.record_rules.title')}
            isDefaultOpen={!shouldDisableRecordRules}
            isDisabled={shouldDisableRecordRules}
            customLabel={
              <div className="w-full text-left">
                <span
                  className={cn('text-sm font-medium text-emphasis', {
                    'text-subtle': shouldDisableRecordRules
                  })}
                >
                  {t('components.rules.record_rules.title')}
                </span>
                {shouldDisableRecordRules && (
                  <p className="text-xs text-muted">
                    {t('components.rules.inline_editing_enabled')}
                  </p>
                )}
              </div>
            }
          >
            {!shouldDisableRecordRules && (
              <TableRecordRules
                column={columnItem}
                updateViewColumn={updateViewColumn}
                sourceObject={sourceObject}
              />
            )}
          </BuilderAccordion.Item>
        )}
        {shouldRenderConnectionSettings && (
          <BuilderAccordion.Item
            label={t(
              'pages.element_settings.table.categories.data_display.field_management.connection_settings'
            )}
            isDefaultOpen
          >
            <TableViewColumnSettingsConnection
              column={columnItem}
              updateViewColumn={updateViewColumn}
              connectionField={columnField}
            />
          </BuilderAccordion.Item>
        )}
        {shouldRenderGrouping && (
          <BuilderAccordion.Item
            label={t(
              'pages.element_settings.common.categories.data_display.field_management.grouping'
            )}
            isDefaultOpen={false}
            className="mt-4"
          >
            <div className="flex items-center gap-2">
              <Label className="flex items-center gap-1 text-subtle">
                <Switch disabled />
                {t(
                  'pages.element_settings.common.categories.data_display.field_management.group_field_value'
                )}
              </Label>
              <Badge className="shrink-0 text-xs">{t('keywords.coming_soon')}</Badge>
            </div>
          </BuilderAccordion.Item>
        )}
        {shouldRenderActionButtonSection && (
          <TableColumnSettingsActionButtonSection
            columnItem={columnItem}
            updateViewColumn={updateViewColumn}
          />
        )}
        <BuilderAccordion.Item
          label={t(
            'pages.element_settings.common.categories.data_display.field_management.display_rules'
          )}
          isDefaultOpen
        >
          <TableDisplayRules column={columnItem} updateViewColumn={updateViewColumn} />
        </BuilderAccordion.Item>
        <BuilderAccordion.Item
          label={t(
            'pages.element_settings.common.categories.data_display.field_management.field_formatting'
          )}
          isDefaultOpen
        >
          <FieldFormattingSettings columnItem={columnItem} updateViewColumn={updateViewColumn} />
        </BuilderAccordion.Item>
      </BuilderAccordion>
    </div>
  );
}
