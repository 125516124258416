import { useTranslation } from 'react-i18next';
import { Chip, Input, Label, Switch } from '@knack/asterisk-react';
import capitalize from 'lodash/capitalize';

import { type BuilderViewSourceCriteriaRule } from '@/types/schema/BuilderView';
import { type TableView } from '@/types/schema/views/TableView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { SourceFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersDialog';
import { SourceFiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersGroupCard';
import { SourceFiltersWarningBanner } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersWarningBanner';
import { ColumnSummariesDialog } from '@/pages/pages/settings-panel/view-settings/table/data-display/ColumnSummariesDialog';
import { SortingDialog } from '@/pages/pages/settings-panel/view-settings/table/data-display/sorting/SortingDialog';
import { SortingListItem } from '@/pages/pages/settings-panel/view-settings/table/data-display/sorting/SortingListItem';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

const DEFAULT_RECORDS_LIMIT = '25';

export interface SourceFiltersSubmitParams {
  criteria: BuilderViewSourceCriteriaRule[];
}

export function DataDisplayGeneralSettings() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<TableView>();

  const updateViewSchema = useUpdateView<TableView>();

  const columnSummaries = view.totals;
  // This option has been removed in v4. For old apps, we will show a warning banner.
  const shouldShowSourceFiltersWarning =
    view.source.criteria.match === 'all' && view.source.criteria.rules.length > 0;

  const handleChangeLimit = (value: boolean) => {
    if (value) {
      updateViewSchema({
        source: {
          ...view.source,
          limit: undefined
        }
      });
      return;
    }

    updateViewSchema({
      source: {
        ...view.source,
        limit: DEFAULT_RECORDS_LIMIT
      }
    });
  };

  const handleChangeRecordsLimitInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    resetValue?: boolean
  ) => {
    if (resetValue && e.target.value === '') {
      updateViewSchema({
        source: {
          ...view.source,
          limit: undefined
        }
      });
      return;
    }

    updateViewSchema({
      source: {
        ...view.source,
        limit: e.target.value
      }
    });
  };

  const handleSourceFiltersSubmit = (data: BuilderViewSourceCriteriaRule[][]) => {
    const [firstGroup, ...groups] = data;

    updateViewSchema({
      source: {
        ...view.source,
        criteria: {
          match: 'any',
          rules: firstGroup || [],
          groups: groups || []
        }
      }
    });
  };

  return (
    <div className="space-y-4">
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t(
            'pages.element_settings.common.categories.data_display.general_settings.column_summaries'
          )}
        >
          <div className="space-y-4">
            <p className="text-xs">
              {t(
                'pages.element_settings.common.categories.data_display.general_settings.summaries_description'
              )}
            </p>
            {columnSummaries && columnSummaries.length > 0 && (
              <div className="space-y-2">
                {columnSummaries.map((summary, index) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${summary.label}-${index}`}
                    className="flex items-center justify-between gap-2 rounded-lg bg-subtle p-2"
                  >
                    <span>{summary.label}</span>
                    <Chip
                      className="pointer-events-none m-0 h-auto bg-emphasis py-0 font-normal"
                      text={capitalize(summary.calc)}
                    />
                  </div>
                ))}
              </div>
            )}
            <ColumnSummariesDialog />
          </div>
        </BuilderAccordion.Item>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t(
            'pages.element_settings.common.categories.data_display.general_settings.source_filters'
          )}
        >
          <div className="space-y-4">
            {shouldShowSourceFiltersWarning && <SourceFiltersWarningBanner />}
            <SourceFiltersGroupsCard
              sourceObject={sourceObject}
              sourceFiltersCriteria={view.source.criteria}
            />
            <SourceFiltersDialog
              sourceObject={sourceObject}
              sourceFiltersCriteria={view.source.criteria}
              onFormSubmit={handleSourceFiltersSubmit}
            />
          </div>
        </BuilderAccordion.Item>
        <BuilderAccordion.Item isDefaultOpen label={t('keywords.sorting')}>
          <div className="space-y-4">
            <div className="space-y-2">
              {view.source.sort?.map((sortItem, index) => (
                <SortingListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${sortItem.field}-${index}`}
                  sortItem={sortItem}
                />
              ))}
            </div>
            <SortingDialog />
          </div>
        </BuilderAccordion.Item>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.common.categories.data_display.general_settings.limit')}
        >
          <Label className="flex items-center gap-2">
            <Switch
              checked={view.source?.limit === undefined}
              onCheckedChange={handleChangeLimit}
            />
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.show_all_records'
            )}
          </Label>
          {view.source?.limit !== null && view.source?.limit !== undefined && (
            <div className="ml-10 mt-2 flex items-center gap-1">
              <span>
                {t(
                  'pages.element_settings.common.categories.data_display.general_settings.show_the_first'
                )}
              </span>
              <Input
                className="h-auto w-10 px-2 py-1.5"
                value={view.source.limit}
                onChange={handleChangeRecordsLimitInput}
                // Reset the value to undefined when the user leaves an empty string and click Save Changes
                onBlur={(e) => handleChangeRecordsLimitInput(e, true)}
              />
              <span>{t('keywords.records')}</span>
            </div>
          )}
        </BuilderAccordion.Item>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t(
            'pages.element_settings.common.categories.data_display.general_settings.empty_state'
          )}
        >
          <Label>
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.empty_table_text'
            )}
            <Input
              className="my-2"
              value={view.no_data_text}
              onChange={(e) =>
                updateViewSchema({
                  no_data_text: e.target.value
                })
              }
            />
          </Label>
          <p className="mb-4 text-xs text-subtle">
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.empty_records_text_description'
            )}
          </p>
          <Label className="mb-4 flex items-center gap-2">
            <Switch
              checked={view.hide_empty}
              onCheckedChange={(value) =>
                updateViewSchema({
                  hide_empty: value
                })
              }
            />
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.hide_empty_columns'
            )}
          </Label>
        </BuilderAccordion.Item>
      </BuilderAccordion>
    </div>
  );
}
