import { useState, type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCollapseVertical as CollapseIcon, BiExpandVertical as ExpandIcon } from 'react-icons/bi';
import {
  HiTrash as DeleteIcon,
  HiDocumentDuplicate as DuplicateIcon,
  HiEye as EyeIcon,
  HiEyeSlash as HideEyeIcon,
  HiKey as KeyIcon,
  HiLockClosed as LockIcon,
  HiCog6Tooth as SettingsIcon
} from 'react-icons/hi2';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Dialog, DropdownMenu, useToast } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { copyToClipboard } from '@/utils/copy-to-clipboard';
import { usePageEditorUiOptionsContext } from '@/pages/pages/page-editor/PageEditorUiOptionsContext';
import { usePagesPageContext } from '@/pages/pages/PagesPageContext';
import { ROUTES } from '@/Router';
import { DialogContent } from './DialogContent';

export type DialogContentType =
  | 'new'
  | 'delete'
  | 'duplicate'
  | 'menu_settings'
  | 'remove_login'
  | 'add_login';

export interface PageDropdownMenuProps {
  page: BuilderPage;
  triggerElement: React.ReactNode;
  shouldShowPageEditorUiOptions?: boolean;
  dropdownContentAlign?: ComponentProps<typeof DropdownMenu.Content>['align'];
  onDropdownMenuOpenChange?: (isOpen: boolean, pageKey: string) => void;
}

function PageEditorUiOptionsMenuItems({ page }: { page: BuilderPage }) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { pageEditorUiOptions, setPageEditorUiOptions } = usePageEditorUiOptionsContext();
  const { shouldShowViewLabels, shouldShowViewKeys } = pageEditorUiOptions;

  const onPageKeyClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    try {
      await copyToClipboard(page.key);
      presentToast({
        title: t('pages.page_editor.page_key_copied_to_clipboard')
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Copy to clipboard not supported on insecure origins');
    }
  };

  return (
    <>
      <DropdownMenu.Item onClick={onPageKeyClick} className="flex items-center justify-between">
        <KeyIcon size={16} className="mr-2" />
        <span className="mr-1">{t('pages.page_editor.copy_page_key')}</span>
        <span className="ml-auto rounded-xl bg-subtle px-2 py-0.5 text-xs leading-none text-subtle">
          {page.key}
        </span>
      </DropdownMenu.Item>
      <DropdownMenu.Item
        onClick={() =>
          setPageEditorUiOptions({
            shouldShowViewLabels: !shouldShowViewLabels
          })
        }
      >
        {shouldShowViewLabels ? (
          <>
            <HideEyeIcon size={16} className="mr-2" />
            {t('pages.page_editor.hide_view_labels')}
          </>
        ) : (
          <>
            <EyeIcon size={16} className="mr-2" />
            {t('pages.page_editor.show_view_labels')}
          </>
        )}
      </DropdownMenu.Item>
      <DropdownMenu.Item
        onClick={() =>
          setPageEditorUiOptions({
            shouldShowViewKeys: !shouldShowViewKeys
          })
        }
      >
        {shouldShowViewKeys ? (
          <>
            <HideEyeIcon size={16} className="mr-2" />
            {t('pages.page_editor.hide_view_keys')}
          </>
        ) : (
          <>
            <EyeIcon size={16} className="mr-2" />
            {t('pages.page_editor.show_view_keys')}
          </>
        )}
      </DropdownMenu.Item>
      <DropdownMenu.Item
        onClick={() => {
          setPageEditorUiOptions({
            shouldCollapseSections: true
          });
        }}
      >
        <CollapseIcon className="mr-2" size={16} />
        {t('pages.page_editor.collapse_sections')}
      </DropdownMenu.Item>
      <DropdownMenu.Item
        onClick={() => {
          setPageEditorUiOptions({
            shouldCollapseSections: false
          });
        }}
      >
        <ExpandIcon className="mr-2" size={16} />
        {t('pages.page_editor.expand_sections')}
      </DropdownMenu.Item>
      <DropdownMenu.Separator />
    </>
  );
}

export function PageDropdownMenu({
  page,
  triggerElement,
  shouldShowPageEditorUiOptions,
  dropdownContentAlign,
  onDropdownMenuOpenChange
}: PageDropdownMenuProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams<{ id: string }>();

  const { setSettingsPanelItem } = usePagesPageContext();
  const { isTopLevelPage, isGlobalLoginPage, isPageValidForAddingLogin } = usePageHelpers();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContentType, setDialogContentType] = useState<DialogContentType | null>(null);

  const { id: pageKey } = urlParams;

  // We show the 'delete page' option for all pages expect for the global login page, since it can't be deleted
  const shouldShowDeleteOption = !isGlobalLoginPage(page);

  // We show the 'remove login' option for authentication pages, but not for the global login page
  const shouldShowRemoveLoginOption = isGlobalLoginPage(page)
    ? false
    : page.type === 'authentication';

  const shouldShowAddLoginOption = isPageValidForAddingLogin(page);

  const shouldShowDuplicateOption = page.type !== 'menu' && isTopLevelPage(page);

  const handleOnSettingsClick = () => {
    if (page.type === 'menu') {
      setIsDialogOpen(true);
      setDialogContentType('menu_settings');
      return;
    }

    // If opening the settings panel for the same page, just update the settings panel item
    if (pageKey === page.key) {
      setSettingsPanelItem({ type: 'page', page });
      return;
    }

    // Otherwise, navigate to the appropriate page
    navigate(generatePath(ROUTES.PAGES_ID, { id: page.key }));
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DropdownMenu
        open={isDropdownOpen}
        onOpenChange={(isOpen) => {
          if (onDropdownMenuOpenChange) {
            onDropdownMenuOpenChange(isOpen, page.id);
          }
          setDropdownOpen(isOpen);
        }}
        data-testid={`dropdown-menu-${page.id}`}
        modal={false}
      >
        <DropdownMenu.Trigger data-testid={`dropdown-trigger-${page.id}`} asChild>
          {triggerElement}
        </DropdownMenu.Trigger>

        <DropdownMenu.Content
          data-testid={`dropdown-content-${page.id}`}
          align={dropdownContentAlign}
        >
          {shouldShowPageEditorUiOptions && <PageEditorUiOptionsMenuItems page={page} />}

          <DropdownMenu.Item
            onClick={handleOnSettingsClick}
            data-testid={`dropdown-settings-item-${page.id}`}
          >
            <SettingsIcon size={16} className="mr-2" />
            {t(`pages.page_tree.${page.type}_settings`)}
          </DropdownMenu.Item>
          {shouldShowAddLoginOption && (
            <DropdownMenu.Item
              onSelect={() => {
                setIsDialogOpen(true);
                setDialogContentType('add_login');
              }}
              data-testid={`dropdown-add-login-item-${page.id}`}
            >
              <LockIcon className="mr-2" size={16} />
              {t('pages.page_tree.add_login')}
            </DropdownMenu.Item>
          )}
          {shouldShowRemoveLoginOption && (
            <DropdownMenu.Item
              onSelect={() => {
                setIsDialogOpen(true);
                setDialogContentType('remove_login');
              }}
              data-testid={`dropdown-remove-login-item-${page.id}`}
            >
              <LockIcon className="mr-2" size={16} />
              {t('pages.page_tree.remove_login')}
            </DropdownMenu.Item>
          )}
          {shouldShowDuplicateOption && (
            <DropdownMenu.Item
              onSelect={() => {
                setIsDialogOpen(true);
                setDialogContentType('duplicate');
              }}
              data-testid={`dropdown-duplicate-item-${page.id}`}
            >
              <DuplicateIcon className="mr-2" size={16} />
              {t(`pages.page_tree.${page.type}_duplicate`)}
            </DropdownMenu.Item>
          )}
          {shouldShowDeleteOption && (
            <>
              <DropdownMenu.Separator />
              <DropdownMenu.Item
                onSelect={() => {
                  setIsDialogOpen(true);
                  setDialogContentType('delete');
                }}
                className="text-destructive"
                data-testid={`dropdown-delete-item-${page.id}`}
              >
                <DeleteIcon className="mr-2" size={16} />
                {t(`pages.page_tree.${page.type}_delete`)}
              </DropdownMenu.Item>
            </>
          )}
        </DropdownMenu.Content>
      </DropdownMenu>

      <DialogContent
        page={page}
        contentType={dialogContentType}
        closeDialog={() => setIsDialogOpen(false)}
      />
    </Dialog>
  );
}
