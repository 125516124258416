import {
  type BaseKnackField,
  type ConditionalRule,
  type ConditionalRuleCriteria,
  type ConditionalRuleValues,
  type ValidationRule,
  type ValidationRuleCriteria
} from '@/types/schema/KnackField';
import type { DateTimeOperator, DateTimeValidationOperator } from '@/types/schema/KnackOperator';

export interface DateTimeField extends BaseKnackField {
  type: 'date_time';
  format: DateTimeFieldFormat;
  rules: DateTimeFieldConditionalRule[];
  validation: DateTimeFieldValidationRule[];
}

export type DateTimeFieldRepeatWeekDay = {
  SU: boolean;
  MO: boolean;
  TU: boolean;
  WE: boolean;
  TH: boolean;
  FR: boolean;
  SA: boolean;
};

export interface DateTimeFieldFormat {
  calendar?: boolean; // This is the option for "Yes, add options like repeat and end-time to use with a calendar."
  date_format?: DateFormatValue;
  default_date?: string;
  default_time?: string;
  default_type?: DefaultDateType;
  time_format?: TimeFormatValue;
  time_type?: DefaultTimeType;
}

export type DefaultDateType = (typeof DEFAULT_DATE_TYPE_OPTIONS)[number];
export type DefaultTimeType = (typeof DEFAULT_TIME_TYPE_OPTIONS)[number];
export type DateFormatValue = (typeof DATE_FORMAT_OPTIONS)[number];
export type TimeFormatValue = (typeof TIME_FORMAT_OPTIONS)[number];
export type DateTimeRangeType = (typeof DATE_TIME_RANGE_TYPES)[number];

export const DATE_FORMAT_OPTIONS = [
  'mm/dd/yyyy',
  'dd/mm/yyyy',
  'M D, yyyy',
  'Ignore Date'
] as const;
export const TIME_FORMAT_OPTIONS = ['HH:MM am', 'HH MM (military)', 'Ignore Time'] as const;
export const DEFAULT_DATE_TYPE_OPTIONS = ['current', 'date', 'none'] as const;
export const DEFAULT_TIME_TYPE_OPTIONS = ['current', 'time', 'none'] as const;
export const DATE_TIME_RANGE_TYPES = [
  'days',
  'week',
  'weeks',
  'month',
  'months',
  'quarter',
  'year',
  'years',
  'rolling weeks',
  'rolling months',
  'rolling years'
] as const;

// Validation Rules
interface DateTimeFieldValidationRule extends ValidationRule {
  criteria: DateTimeFieldValidationRuleCriteria[];
}

export interface DateTimeFieldValidationRuleCriteria extends ValidationRuleCriteria {
  type?: DateTimeRangeType;
  range: string | number;
  from_date?: number | { date: string };
  to_date?: number | { date: string };
  operator: DateTimeValidationOperator;
  value:
    | number
    | {
        all_day?: boolean;
        date?: string;
        hours?: number;
        minutes?: number;
        time?: string;
        am_pm?: string;
        from_date?: {
          date: string;
        };
        to_date?: {
          all_day?: boolean;
          date?: string;
          hours?: number;
          minutes?: number;
          time?: number;
          am_pm?: string;
        };
      };
}

// Conditional Rules
interface DateTimeFieldConditionalRule extends ConditionalRule {
  values: DateTimeFieldConditionalRuleValues[];
  criteria: DateTimeFieldConditionalRuleCriteria[];
}

export interface DateTimeFieldConditionalRuleValues extends ConditionalRuleValues {
  type: 'current_date' | 'value' | 'record';
  value: DateTimeFieldConditionalRuleValue;
}

export interface DateTimeFieldConditionalRuleValue {
  all_day: boolean;
  date: string;
  hours?: number;
  minutes?: number;
  time?: string;
  am_pm?: string;
  to?: {
    date: string;
    hours?: number;
    minutes?: number;
    time?: string;
    am_pm?: string;
  };
  repeat?: {
    interval: number;
    endson: 'never' | 'date' | 'limit';
    end_count: number | string;
    end_date: string;
  } & DateTimeFieldConditionalRuleRepeat;
}

type DateTimeFieldConditionalRuleRepeat =
  | {
      frequency: 'daily';
    }
  | ({
      frequency: 'weekly';
    } & DateTimeFieldRepeatWeekDay)
  | {
      frequency: 'monthly';
      repeatby: 'dow' | 'dom';
    };

export interface DateTimeFieldConditionalRuleCriteria extends ConditionalRuleCriteria {
  operator: DateTimeOperator;
  range?: string;
  value: DateTimeFieldConditionalRuleCriteriaValue;
  type?: DateTimeRangeType;
}

export interface DateTimeFieldConditionalRuleCriteriaValue {
  all_day: boolean;
  date?: string;
  hours?: number;
  minutes?: number;
  time?: string;
  am_pm?: string;
}
