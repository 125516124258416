import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function GeneralSettingsUserOptions() {
  const { view } = useActiveViewContext<CalendarView>();
  const [t] = useTranslation();
  const updateViewSchema = useUpdateView();

  const handleDetails = (showDetails: boolean) =>
    updateViewSchema({ events: { ...view.events, show_details: showDetails } });
  const handleAdd = (add: boolean) =>
    updateViewSchema({ events: { ...view.events, allow_add: add } });
  const handleEdit = (edit: boolean) =>
    updateViewSchema({ events: { ...view.events, allow_edit: edit } });
  const handleDelete = (val: boolean) =>
    updateViewSchema({ events: { ...view.events, allow_delete: val } });
  const handleRss = (rss: boolean) => updateViewSchema({ events: { ...view.events, rss } });
  const handleIcal = (ical: boolean) => updateViewSchema({ events: { ...view.events, ical } });

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t(
        'pages.element_settings.calendar.categories.general_settings.section_titles.user_options'
      )}
    >
      <div className="flex flex-col gap-4">
        <Label className="flex items-center gap-2" htmlFor="showDetails">
          <Switch
            checked={view.events.show_details}
            onCheckedChange={handleDetails}
            id="showDetails"
          />
          {t('pages.element_settings.calendar.categories.general_settings.show_details')}
        </Label>
        <Label className="flex items-center gap-2" htmlFor="addEvents">
          <Switch checked={view.events.allow_add} onCheckedChange={handleAdd} id="addEvents" />
          {t('pages.element_settings.calendar.categories.general_settings.add_events')}
        </Label>
        <Label className="flex items-center gap-2" htmlFor="editEvents">
          <Switch checked={view.events.allow_edit} onCheckedChange={handleEdit} id="editEvents" />
          {t('pages.element_settings.calendar.categories.general_settings.edit_events')}
        </Label>
        <Label className="flex items-center gap-2" htmlFor="deleteEvents">
          <Switch
            checked={view.events.allow_delete}
            onCheckedChange={handleDelete}
            id="deleteEvents"
          />
          {t('pages.element_settings.calendar.categories.general_settings.delete_events')}
        </Label>
        <Label className="flex items-center gap-2" htmlFor="exportCalendar">
          <Switch checked={view.events.ical} onCheckedChange={handleIcal} id="exportCalendar" />
          {t('pages.element_settings.calendar.categories.general_settings.export_events')}
        </Label>
        <Label className="flex items-center gap-2" htmlFor="subscribe">
          <Switch checked={view.events.rss} onCheckedChange={handleRss} id="subsribe" />
          {t('pages.element_settings.calendar.categories.general_settings.subscribe')}
        </Label>
      </div>
    </BuilderAccordion.Item>
  );
}
