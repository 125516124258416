import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiTrash as DeleteIcon,
  HiBolt as DynamicIcon,
  HiPencil as EditIcon,
  HiLink as LinkIcon,
  HiRectangleStack as ViewIcon
} from 'react-icons/hi2';
import { Dialog } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import {
  type DetailsView,
  type DetailsViewChildScenePayload,
  type DetailsViewInput
} from '@/types/schema/views/DetailsView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import {
  generateDetailsViewDeleteRecordInput,
  generateDetailsViewDynamicActionInput,
  generateDetailsViewEditRecordInput,
  generateDetailsViewLinkInput,
  generateDetailsViewRecordDetailsInput,
  getDetailsViewSchemaFromPartialView
} from '@/pages/pages/page-editor/add-view/helpers/view-schemas/detailsViewSchema';
import { getFormViewSchemaFromPartialView } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/formViewSchema';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewActionsSettings } from '@/pages/pages/settings-panel/view-settings/common/details-actions/ViewActionsSettings';
import { useUpdateViewColumns } from '@/pages/pages/settings-panel/view-settings/useUpdateViewColumns';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';

export function ViewWithDetailsActionsSettingsCategory() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<ViewWithDetails>();
  const { page: currentPage } = usePageEditorContext();
  const { setViewInputId, setIsViewInputEditMode } = useViewInputEditModeContext();
  const updateViewColumnsSchema = useUpdateViewColumns<ViewWithDetails>();

  const [isLinkToExistingPageDialogOpen, setIsLinkToExistingPageDialogOpen] = useState(false);

  let { columns } = view;
  if (view.type === 'map') {
    columns = view.details.columns;
  }

  const allInputsInView = useMemo(
    () =>
      columns.reduce<DetailsViewInput[]>((acc, outerColumn) => {
        outerColumn.groups.forEach((group) => {
          group.columns.forEach((column) => {
            column.forEach((input) => {
              acc.push(input);
            });
          });
        });
        return acc;
      }, []),
    [columns]
  );

  const updateColumnWithField = (fieldToAdd: DetailsViewInput) =>
    columns.map((outerColumn, outerColumnIndex) => {
      if (outerColumnIndex !== columns.length - 1) {
        return outerColumn;
      }

      return {
        ...outerColumn,
        groups: outerColumn.groups.map((group, groupIndex) => {
          if (groupIndex === columns[0].groups.length - 1) {
            return {
              ...group,
              columns: group.columns.map((column, columnIndex) => {
                if (columnIndex === group.columns.length - 1) {
                  return [...column, fieldToAdd];
                }
                return column;
              })
            };
          }
          return group;
        })
      };
    });

  const handleClickLinkAction = (isEditAction?: boolean) => {
    const detailsViewName = t('views.new_view_defaults.details.view_details', {
      objectName: sourceObject.inflections.singular
    });
    const editFormViewName = t('views.new_view_defaults.form.title_edit', {
      objectName: sourceObject.inflections.singular
    });

    const childDetailsView = getDetailsViewSchemaFromPartialView(
      {
        name: detailsViewName,
        title: detailsViewName,
        source: {
          object: sourceObject.key,
          criteria: {
            match: 'any',
            groups: [],
            rules: []
          }
        }
      },
      sourceObject
    );

    const childFormView = getFormViewSchemaFromPartialView(
      {
        action: 'update',
        name: editFormViewName,
        title: editFormViewName,
        source: {
          object: sourceObject.key,
          criteria: {
            match: 'any',
            groups: [],
            rules: []
          }
        }
      },
      sourceObject
    );

    const viewToAdd = isEditAction ? childFormView : childDetailsView;

    const newChildPageWithDefaultView: DetailsViewChildScenePayload = {
      name: viewToAdd.name,
      object: sourceObject.key,
      parent: currentPage.slug,
      views: [
        {
          ...viewToAdd
        }
      ]
    };

    const newInput = isEditAction
      ? generateDetailsViewEditRecordInput(newChildPageWithDefaultView)
      : generateDetailsViewRecordDetailsInput(newChildPageWithDefaultView);

    const updatedView: Partial<DetailsView> = {
      columns: updateColumnWithField(newInput)
    };

    updateViewColumnsSchema(updatedView);
  };

  const handleClickDeleteRecordAction = () => {
    const newDeleteRecordInput = generateDetailsViewDeleteRecordInput();

    const updatedView: Partial<DetailsView> = {
      columns: updateColumnWithField(newDeleteRecordInput)
    };

    updateViewColumnsSchema(updatedView);
  };

  const handleApplyLinkToExistingPage = (selectedPage: BuilderPage) => {
    const newLinkFieldInput = generateDetailsViewLinkInput(selectedPage);

    const updatedView: Partial<DetailsView> = {
      columns: updateColumnWithField(newLinkFieldInput)
    };

    updateViewColumnsSchema(updatedView);
  };

  const handleClickDynamicAction = () => {
    const newDynamicActionInput = generateDetailsViewDynamicActionInput();

    const updatedView: Partial<DetailsView> = {
      columns: updateColumnWithField(newDynamicActionInput)
    };

    updateViewColumnsSchema(updatedView);

    // Open the action column settings automatically
    setIsViewInputEditMode(true);
    setViewInputId(newDynamicActionInput.id);
  };

  const viewActionList = [
    {
      key: 'edit',
      label: t('pages.element_settings.common.categories.actions.edit_record'),
      icon: EditIcon,
      onClick: () => handleClickLinkAction(true),
      dataTestId: 'button-edit-record-action'
    },
    {
      key: 'view',
      label: t('pages.element_settings.common.categories.actions.view_record'),
      icon: ViewIcon,
      onClick: handleClickLinkAction,
      dataTestId: 'button-view-record-details-action'
    },
    {
      key: 'delete',
      label: t('pages.element_settings.common.categories.actions.delete_record'),
      icon: DeleteIcon,
      onClick: handleClickDeleteRecordAction,
      dataTestId: 'button-delete-record-action',
      isDisabled: allInputsInView.some((column) => column.type === 'delete')
    },
    {
      key: 'link',
      label: t('pages.element_settings.common.categories.actions.link_record'),
      icon: LinkIcon,
      onClick: () => setIsLinkToExistingPageDialogOpen(true),
      dataTestId: 'button-link-to-existing-page-action'
    },
    {
      key: 'action_link',
      label: t('pages.element_settings.common.categories.actions.dynamic_action'),
      icon: DynamicIcon,
      onClick: handleClickDynamicAction,
      dataTestId: 'button-dynamic-action-action'
    }
  ];

  return (
    <div className="space-y-2">
      <Dialog
        open={isLinkToExistingPageDialogOpen}
        onOpenChange={setIsLinkToExistingPageDialogOpen}
      >
        <ViewActionsSettings
          sourceObject={sourceObject}
          actions={viewActionList}
          onApplyLinkToExistingPage={handleApplyLinkToExistingPage}
          handleCloseLinkToExistingPageDialog={() => setIsLinkToExistingPageDialogOpen(false)}
        />
      </Dialog>
    </div>
  );
}
