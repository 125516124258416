import React, { useRef, useState } from 'react';
import {
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon,
  HiArrowTopRightOnSquare as LinkIcon
} from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { DataDisplaySettings } from '@/pages/pages/settings-panel/view-settings/calendar/DataDisplaySettings';
import { EventDetailsSettings } from '@/pages/pages/settings-panel/view-settings/calendar/EventDetailsSettings';
import { EventFormSettings } from '@/pages/pages/settings-panel/view-settings/calendar/EventFormSettings';
import { GeneralSettings } from '@/pages/pages/settings-panel/view-settings/calendar/general/GeneralSettings';
import { CommonSourceBanner } from '@/pages/pages/settings-panel/view-settings/common/CommonSourceBanner';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

type CalendarSettingsCategoryId =
  | 'general-settings'
  | 'data-display'
  | 'event-detail'
  | 'event-form';
type CalendarSettingsCategoryItem = ViewSettingsCategoryItem<CalendarSettingsCategoryId>;

function getPanelTitle(
  activeCategoryId: CalendarSettingsCategoryId | undefined,
  categories: CalendarSettingsCategoryItem[]
) {
  const activeCategory = activeCategoryId
    ? categories.find((category) => category.id === activeCategoryId)
    : undefined;

  return activeCategory ? activeCategory.name : t('pages.element_settings.calendar.heading');
}

const CALENDAR_SETTINGS_CATEGORIES: CalendarSettingsCategoryItem[] = [
  {
    id: 'general-settings',
    name: t('pages.element_settings.calendar.categories.general_settings.name'),
    description: t('pages.element_settings.calendar.categories.general_settings.description'),
    icon: GeneralSettingsIcon
  },
  {
    id: 'data-display',
    name: t('pages.element_settings.common.categories.data_display.name'),
    description: t('pages.element_settings.common.categories.data_display.description'),
    icon: FieldsIcon
  },
  {
    id: 'event-detail',
    name: t('pages.element_settings.calendar.categories.event_details.name'),
    description: t('pages.element_settings.calendar.categories.event_details.description'),
    icon: ColumnsIcon
  },
  {
    id: 'event-form',
    name: t('pages.element_settings.calendar.categories.event_form.name'),
    description: t('pages.element_settings.calendar.categories.event_form.description'),
    icon: ColumnsIcon
  }
];

export function CalendarViewSettings() {
  const [activeCategoryId, setActiveCategoryId] = useState<CalendarSettingsCategoryId | undefined>(
    undefined
  );
  const panelTitle = getPanelTitle(activeCategoryId, CALENDAR_SETTINGS_CATEGORIES);
  const { view } = useActiveViewContext<CalendarView>();
  const navigationRef = useRef<Array<CalendarSettingsCategoryId | undefined>>([undefined]);

  const handleCategorySelect = (selectedCategoryId: string) => {
    const categoryId = selectedCategoryId as CalendarSettingsCategoryId;
    navigationRef.current.push(categoryId);
    setActiveCategoryId(categoryId);
  };

  const handleBack = () => {
    // remove the current trace from navigation tree
    navigationRef.current.pop();
    const currentState = navigationRef.current.slice(-1)?.[0];
    setActiveCategoryId(currentState);
  };

  return (
    <CollapsiblePanel.Content
      title={panelTitle}
      // render back button only when activeCategoryId is defined
      {...(activeCategoryId && {
        onBack: handleBack,
        backTitle:
          navigationRef.current.slice(-2)?.[0] || t('pages.element_settings.calendar.heading')
      })}
      footer={
        <Link
          to="https://learn.knack.com/article/7hyhd2qtgv-calendars"
          className="flex items-center justify-center gap-2 p-4 underline"
        >
          {t('pages.element_settings.calendar.categories.general_settings.learn_more')} <LinkIcon />
        </Link>
      }
    >
      {activeCategoryId ? (
        <>
          {activeCategoryId === 'general-settings' && <GeneralSettings />}
          {activeCategoryId === 'data-display' && <DataDisplaySettings />}
          {activeCategoryId === 'event-detail' && <EventDetailsSettings />}
          {activeCategoryId === 'event-form' && <EventFormSettings />}
        </>
      ) : (
        <>
          <CommonSourceBanner view={view} />
          <ViewSettingsCategoryList
            categories={CALENDAR_SETTINGS_CATEGORIES}
            onCategorySelect={handleCategorySelect}
          />
        </>
      )}
    </CollapsiblePanel.Content>
  );
}
