import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, RichTextRenderer, Spinner } from '@knack/asterisk-react';
import DOMPurify from 'dompurify';

import { useChangeUsersStatus } from '@/hooks/api/mutations/useChangeUsersStatus';
import { useApprovalTemplateQuery } from '@/hooks/api/queries/useApprovalTemplatesQuery';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { assertTruthiness } from '@/utils/assert';
import { ApiErrorBanner } from '@/components/errors/ApiErrorBanner';
import { replaceTemplateVariables } from '@/components/field-settings/utils/replaceTemplateVariables';
import { useEmailTemplateKeyName } from '@/pages/roles/role-settings/useEmailTemplateKeyName';

type SendWelcomeSubPageParams = {
  id: string;
  rowId: string;
};

function SendWelcomeEmail({ tableKey, rowId }: { tableKey: string; rowId: string }) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const table = useObjectHelpers().getObjectByKey(tableKey);
  assertTruthiness(table?.profile_key, 'No user table found');

  const { emailTemplateKeyVariables, emailTemplateNameVariables } = useEmailTemplateKeyName();

  const { mutate: sendWelcomeMutate, isSuccess, isPending, error } = useChangeUsersStatus();
  const { data: accountInfoTemplate, isLoading } = useApprovalTemplateQuery(table.profile_key);

  const sanitizedValue = DOMPurify.sanitize(
    replaceTemplateVariables({
      text: accountInfoTemplate || '',
      variableList: emailTemplateKeyVariables,
      valueList: emailTemplateNameVariables.map((name) => `{${name}}`)
    })
  );

  if (isPending) {
    return (
      <div className="flex items-center justify-center p-8">
        <Spinner />
      </div>
    );
  }

  if (isSuccess) {
    return (
      <div>
        <p>{t(`components.data_table.row_actions.send_welcome_email_modal.success`)}</p>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('actions.continue')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      {error && (
        <ApiErrorBanner className="mb-4" error={error} fallbackKey="errors.generic_error" />
      )}
      <p className="mb-4">{t(`components.data_table.row_actions.send_welcome_email_modal.text`)}</p>

      <p className="mb-2 font-medium">
        {t(`components.data_table.row_actions.send_welcome_email_modal.preview_text`)}
      </p>
      <div className="max-h-[400px] overflow-auto rounded bg-subtle p-4">
        {isLoading ? (
          <Spinner />
        ) : (
          <RichTextRenderer
            dangerouslySetInnerHTML={{
              __html: sanitizedValue
            }}
          />
        )}
      </div>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          intent="minimal"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t('actions.cancel')}
        </Button>
        <Button
          onClick={() => {
            sendWelcomeMutate({
              ids: [rowId],
              tableKey,
              accountStatus: 'active',
              sendEmailApproval: true
            });
          }}
        >
          {t(`components.data_table.row_actions.send_welcome_email_modal.title`)}
        </Button>
      </div>
    </div>
  );
}

export function SendWelcomeSubPage() {
  const { id, rowId } = useParams<SendWelcomeSubPageParams>();
  const [t] = useTranslation();
  const navigate = useNavigate();

  if (!id || !rowId) {
    throw new Error('SendWelcomeSubPage url parameters are wrong');
  }

  return (
    <Dialog
      open
      onOpenChange={() => {
        navigate(-1);
      }}
    >
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header className="mb-4">
            <Dialog.Title>
              {t(`components.data_table.row_actions.send_welcome_email_modal.title`)}
            </Dialog.Title>
          </Dialog.Header>
          <SendWelcomeEmail tableKey={id} rowId={rowId} />
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}
