import { useState } from 'react';
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, Form, useToast } from '@knack/asterisk-react';
import { t as tFunction } from 'i18next';
import { z } from 'zod';

import {
  type KnackField,
  type KnackFieldKey,
  type ValidationRule
} from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useFieldMutation } from '@/hooks/api/mutations/useFieldMutation';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { CriteriaForm } from '@/pages/tables/toolkit-sidebar/rules/CriteriaForm';
import { RuleFormHeader } from '@/pages/tables/toolkit-sidebar/rules/RuleFormHeader';
import { getCompatibleFields } from '@/pages/tables/toolkit-sidebar/rules/utils';
import { type RuleToDuplicate } from '@/pages/tables/toolkit-sidebar/rules/validation-rules/ValidationRulesPanel';
import { DiscardRuleDialog } from './DiscardRuleDialog';
import { FormFooter } from './form-sections/FormFooter';
import { FormHeader } from './form-sections/FormHeader';
import { FormMessage } from './form-sections/FormMessage';
import { FormTargetField } from './form-sections/FormTargetField';

const validationRuleFormSchema = z
  .object({
    targetFieldKey: z.string(),
    key: z.string().optional(),
    criteria: z.array(
      z.object({
        field: z.custom<KnackFieldKey>(),
        operator: z.string(),
        value: z.union([z.string(), z.boolean(), z.array(z.string()), z.any()]).optional(),
        from_date: z.union([z.number(), z.any()]).optional(),
        to_date: z.union([z.number(), z.any()]).optional(),
        type: z.string().optional(),
        range: z.string().optional(),
        // Only used by `image` and `file` field types
        unit: z.string().optional()
      })
    ),

    message: z
      .string()
      .min(
        1,
        tFunction('components.data_table.right_sidebar.validation_rules.errors.missing_message')
      ),

    value: z.string()
  })
  .superRefine((data, context) => {
    data.criteria.forEach((criterion, index) => {
      const { operator, value, type } = criterion;

      if (
        (operator === 'is between dates' || operator === 'is between days of the week') &&
        criterion.from_date &&
        criterion.to_date
      ) {
        return;
      }

      if (
        !shouldHideValueBasedOnOperator(operator) &&
        (value === '' ||
          value === null ||
          value === undefined ||
          (typeof value === 'string' && value.trim() === '')) &&
        !type
      ) {
        context.addIssue({
          path: ['criteria', index, 'value'],
          code: 'custom'
        });
      }
    });
  });

export type ValidationRuleFormSchema = z.infer<typeof validationRuleFormSchema>;

interface ValidationRuleFormProps {
  table: KnackObject;
  setShouldRenderForm: React.Dispatch<React.SetStateAction<boolean>>;
  ruleToEdit?: ValidationRule;
  ruleToDuplicate?: ValidationRule;
  fieldKey?: KnackFieldKey;
  setRuleToDuplicate?: React.Dispatch<React.SetStateAction<RuleToDuplicate | null>>;
}

export function ValidationRuleForm({
  table,
  setShouldRenderForm,
  ruleToEdit,
  ruleToDuplicate,
  fieldKey,
  setRuleToDuplicate
}: ValidationRuleFormProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { mutate: updateField } = useFieldMutation();
  const { getDefaultCriteriaOperator } = useCriteriaHelpers();
  const isDraftModeEnabled = useGlobalState((state) => state.isDraftModeEnabled);
  const [showModal, setShowModal] = useState(false);

  const rule = ruleToEdit ?? ruleToDuplicate;
  const firstField = getCompatibleFields(table.fields)[0];

  const defaultCriteria = {
    field: firstField.key,
    operator: getDefaultCriteriaOperator(firstField, 'validation-rule'),
    value: ''
  };

  let defaultValues: ValidationRuleFormSchema = {
    targetFieldKey: '',
    criteria: [defaultCriteria],
    message: t('components.data_table.right_sidebar.validation_rules.text_area_default_value'),
    value: ''
  };

  if (rule && fieldKey) {
    defaultValues = {
      targetFieldKey: fieldKey,
      ...rule
    } as ValidationRuleFormSchema;
  }

  const formMethods = useForm<ValidationRuleFormSchema>({
    resolver: zodResolver(validationRuleFormSchema),
    defaultValues
  });

  const {
    formState: { isDirty },
    watch,
    handleSubmit
  } = formMethods;

  const isTargetFieldSelected = !!watch('targetFieldKey');

  const handleCancel = () => {
    if (isDirty) {
      setShowModal(true);
    } else {
      setShouldRenderForm(false);
    }
    setRuleToDuplicate?.(null);
  };

  const onFormSubmit: SubmitHandler<ValidationRuleFormSchema> = (formValues) => {
    if (isDraftModeEnabled) return;
    const { targetFieldKey, ...validationRuleFormValues } = formValues;

    validationRuleFormValues.criteria.map((criterion) => {
      const criterionField = table.fields.find((f) => f.key === criterion.field);
      if (criterionField?.type === 'date_time') {
        if (criterion.operator === 'is during the current') {
          criterion.value = {};
          criterion.from_date = null;
          criterion.to_date = null;
          criterion.range = '';
        }
        if (
          criterion.operator === 'is during the last' ||
          criterion.operator === 'is during the next' ||
          criterion.operator === 'is after the next' ||
          criterion.operator === 'is before the previous' ||
          criterion.operator === 'is during the previous'
        ) {
          criterion.value = {};
          criterion.from_date = null;
          criterion.to_date = null;
        }
        if (criterion.operator === 'is between days of the week') {
          criterion.value = {};
        }
        if (criterion.value.rawTime) {
          let hours = parseInt(criterion.value.rawTime.split(':')[0], 10);
          const minutes = parseInt(criterion.value.rawTime.split(':')[1], 10);

          if (criterion.value.am_pm === 'PM' && hours < 12) {
            hours += 12;
          } else if (criterion.value.am_pm === 'AM' && hours === 12) {
            hours = 0;
          }

          criterion.value.hours = hours.toString().padStart(2, '0');
          criterion.value.minutes = minutes.toString().padStart(2, '0');
          criterion.value.time = `${criterion.value.hours}:${criterion.value.minutes}`;
        }
        if (criterion.value.anyDate || criterionField?.format?.date_format === 'Ignore Date') {
          criterion.value.date = '';
          criterion.value.dateFormatted = '';
          delete criterion.value.anyDate;
          delete criterion.value.anyTime;
        }
        if (criterion.value.anyTime || criterionField?.format?.time_format === 'Ignore Time') {
          criterion.value.time = '';
          criterion.value.hours = '';
          criterion.value.minutes = '';
          criterion.value.rawTime = '';
          criterion.value.am_pm = '';
          delete criterion.value.anyDate;
          delete criterion.value.anyTime;
        }

        if (criterion.value.dateFormatted) {
          criterion.value.date = criterion.value.dateFormatted;
          delete criterion.value.dateFormatted;
        }

        return criterion;
      }
      return criterion;
    });

    const field = table.fields.find((f) => f.key === targetFieldKey);

    if (!field || !field.validation) return;

    const largestKey = Math.max(
      0,
      ...(field.validation.map((v: ValidationRule) => Number(v.key)) || [])
    );
    const newKey = largestKey + 1;

    const updatedField = {
      ...field,
      validation: !ruleToEdit
        ? [
            ...field.validation,
            {
              ...validationRuleFormValues,
              key: newKey.toString(),
              value: ''
            }
          ]
        : field.validation.map((v: ValidationRule) => {
            if (v.key === ruleToEdit?.key) {
              return { ...ruleToEdit, ...validationRuleFormValues };
            }
            return v;
          })
    };
    updateField(
      {
        tableKey: table.key,
        updatedField: updatedField as KnackField // TODO: FE-1944 iteratively refine type while working on additional field types
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.rule_saved')
          });
          setShouldRenderForm(false);

          if (setRuleToDuplicate) {
            setRuleToDuplicate(null);
          }
        },
        onError: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.rule_save_error')
          });
        }
      }
    );
  };

  const getDataTestId = () => {
    if (!rule) {
      return 'validation-rule-form';
    }
    if (ruleToEdit) {
      return 'validation-rule-edit-form';
    }
    return 'validation-rule-duplicate-form';
  };

  return (
    <>
      <div className="space-y-4 rounded-lg bg-muted p-4">
        <FormHeader />
        <FormProvider {...formMethods}>
          <Form
            data-testid={getDataTestId()}
            id="validation-rules-form"
            onSubmit={handleSubmit(onFormSubmit)}
          >
            {!rule && <FormTargetField fieldKey={fieldKey} fields={table.fields} />}
            {isTargetFieldSelected && (
              <>
                {rule ? (
                  <RuleFormHeader fieldKey={fieldKey} table={table} />
                ) : (
                  <Divider className="my-4" />
                )}
                <CriteriaForm
                  table={table}
                  defaultValues={defaultValues}
                  defaultCriteria={defaultCriteria}
                  setShouldRenderForm={setShouldRenderForm}
                />
                <FormMessage />
              </>
            )}
            <FormFooter isTargetFieldSelected={isTargetFieldSelected} handleCancel={handleCancel} />
          </Form>
        </FormProvider>
      </div>
      <DiscardRuleDialog
        showModal={showModal}
        setShowModal={setShowModal}
        setShouldRenderForm={setShouldRenderForm}
      />
    </>
  );
}
