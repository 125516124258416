import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, Form } from '@knack/asterisk-react';
import { type z } from 'zod';

import { type KnackField } from '@/types/schema/KnackField';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { assertTruthiness } from '@/utils/assert';
import { FieldInput } from '@/components/data-table/display/edit-record/FieldInput';
import {
  generateFormSchema,
  getDefaultValues
} from '@/components/data-table/display/edit-record/utils';
import { convertKnackRecordToDataTableRow } from '@/components/data-table/helpers/getRecords';
import { useCurrentTable } from '@/components/data-table/helpers/useCurrentTable';
import {
  useDataTableStore,
  type ServerErrorResponseType
} from '@/components/data-table/useDataTableStore';
import { ROUTES } from '@/Router';

export type EditRecordModalOutletContext = {
  rowId: string;
  fields: KnackField[];
};

function EditRecordModalContent({ rowId, fields }: EditRecordModalOutletContext) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  type FormSchema = z.infer<typeof formSchema>;
  const record = useDataTableStore().use.getCellValues(rowId);
  const tableKey = useDataTableStore().use.objectKey();
  const { getObjectByKey } = useObjectHelpers();
  const table = getObjectByKey(tableKey);

  const currentTable = useCurrentTable();
  const updateRecord = useUpdateRecordMutation();

  const defaultValues = getDefaultValues(record, fields);
  const formSchema = generateFormSchema(record, fields);
  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues
  });

  const { handleSubmit, setError, getFieldState, setFocus } = formMethods;

  const closeDialog = () => {
    const route = currentTable?.type === 'UserObject' ? ROUTES.ROLES_ID : ROUTES.TABLES_ID;
    navigate(generatePath(route, { id: tableKey }));
  };

  const { setRow } = useDataTableStore().use.actions();
  const onFormSubmit: SubmitHandler<FormSchema> = (data) => {
    updateRecord.mutate(
      {
        objectKey: tableKey,
        recordId: rowId,
        data
      },
      {
        onSuccess: (response) => {
          setRow(convertKnackRecordToDataTableRow(response));
          closeDialog();
        },
        onError: (error: unknown) => {
          const errorResponse = error as ServerErrorResponseType;
          if (errorResponse && errorResponse.errors) {
            errorResponse.errors.forEach((err) => {
              const fieldName = err.field as keyof FormSchema;
              setError(fieldName, {
                type: 'manual',
                message: err.message
              });

              const fieldState = getFieldState(fieldName);
              if (fieldState.invalid) {
                setFocus(fieldName);
              }
            });
          }
        }
      }
    );
  };

  const translationPrefix = currentTable?.type === 'UserObject' ? 'user' : 'record';

  if (!table) return null;
  return (
    <Dialog open onOpenChange={() => closeDialog()}>
      <Dialog.Content data-testid={`edit-record-modal-${rowId}`}>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            <Dialog.MainContent className="space-y-6">
              <Dialog.Header>
                <Dialog.Title>
                  {t(`components.data_table.row_actions.edit_${translationPrefix}`)}
                </Dialog.Title>
              </Dialog.Header>
              <Form.Section className="space-y-6">
                {fields?.map((field) => (
                  <div key={field.key} aria-label={field.name}>
                    <FieldInput field={field} table={table} />
                  </div>
                ))}
              </Form.Section>
            </Dialog.MainContent>
            <Dialog.Footer>
              <Dialog.Close asChild>
                <Button intent="minimal" data-testid="edit-record-cancel">
                  {t('actions.cancel')}
                </Button>
              </Dialog.Close>
              <Button data-testid="edit-record-save" type="submit">
                {t('actions.save')}
              </Button>
            </Dialog.Footer>
          </Form>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
}

export function EditRecordModal() {
  const { id, recordId } = useParams();
  assertTruthiness(id && recordId, 'Wrong url parameters');

  const { getObjectByKey } = useObjectHelpers();
  const isInitialLoad = useDataTableStore().use.isInitialLoad();

  const table = getObjectByKey(id);
  if (!table) return null;

  // Wait until the data-table has finished loading

  if (!table || isInitialLoad) return null;

  return <EditRecordModalContent rowId={recordId} fields={table.fields} />;
}
