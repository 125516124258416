import { useMemo } from 'react';

import { type BuilderViewFilterConnectionSources } from '@/types/schema/BuilderView';
import { type ConnectionField } from '@/types/schema/fields';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type ReportViewChart, type ReportViewChartGroup } from '@/types/schema/views/ReportView';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

type ViewsWithRestrictedConnections = TableView | ListView | CalendarView | MapView;

function getActiveTableConnectionFields(columns: TableViewColumn[], fields: KnackField[]) {
  const activeFieldKeys = new Set(columns.map((column) => column.field?.key).filter(Boolean));
  return fields.filter(
    (field) => activeFieldKeys.has(field.key) && field.type === 'connection'
  ) as ConnectionField[];
}

function getActiveDetailsConnectionFields(columns: DetailsViewInput[], fields: KnackField[]) {
  const activeFieldKeys = new Set(columns.map((column) => column.key));
  return fields.filter(
    (field) => activeFieldKeys.has(field.key) && field.type === 'connection'
  ) as ConnectionField[];
}

function getActiveReportConnectionFields(columns: ReportViewChartGroup[], fields: KnackField[]) {
  const activeFieldKeys = columns.map((column) => column.field).filter(Boolean);
  return fields.filter(
    (field) => activeFieldKeys.includes(field.key) && field.type === 'connection'
  ) as ConnectionField[];
}

export function useRestrictedConnectionHelpers() {
  const { sourceObject, view } = useActiveViewContext<ViewsWithRestrictedConnections>();

  const restrictedConnectionFields = useMemo(() => {
    const connectionFieldsInSourceObject = sourceObject.fields.filter(
      (f) => f.type === 'connection'
    );

    if (view.type === 'table') {
      return view.filter_fields === 'object'
        ? connectionFieldsInSourceObject
        : getActiveTableConnectionFields(view.columns, connectionFieldsInSourceObject);
    }

    if (view.type === 'list') {
      const listViewColumns = view.columns.flatMap((column) =>
        column.groups.flatMap((group) => group.columns.flatMap((col) => col))
      );
      return view.filter_fields === 'object'
        ? connectionFieldsInSourceObject
        : getActiveDetailsConnectionFields(listViewColumns, connectionFieldsInSourceObject);
    }

    if (view.type === 'map') {
      const mapViewColumns = view.details.columns.flatMap((column) =>
        column.groups.flatMap((group) => group.columns.flatMap((col) => col))
      );
      return view.details.filter_fields === 'object'
        ? connectionFieldsInSourceObject
        : getActiveDetailsConnectionFields(mapViewColumns, connectionFieldsInSourceObject);
    }

    return [];
  }, [sourceObject, view]);

  const getReportRestrictedConnectionFields = (chart: ReportViewChart) => {
    const connectionFieldsInSourceObject = sourceObject.fields.filter(
      (f) => f.type === 'connection'
    );

    return chart.filters.filter_fields === 'object'
      ? connectionFieldsInSourceObject
      : getActiveReportConnectionFields(chart.groups, connectionFieldsInSourceObject);
  };

  const getDefaultFilterConnectionSources = (fields: ConnectionField[]) => {
    let filterConnectionSources: BuilderViewFilterConnectionSources = {};
    const defaultConnectionSource = { source: { connection_key: '' }, filters: [] };

    fields.forEach((connectionField) => {
      filterConnectionSources = {
        ...filterConnectionSources,
        [connectionField.key]: defaultConnectionSource
      };
    });

    return filterConnectionSources;
  };

  const getFilterConnectionSources = (
    fields: ConnectionField[],
    currentFilterConnectionSources?: BuilderViewFilterConnectionSources
  ) => {
    if (!currentFilterConnectionSources) return undefined;

    let filterConnectionSources: BuilderViewFilterConnectionSources = {};
    const defaultConnectionSource = { source: { connection_key: undefined }, filters: [] };

    fields.forEach((connectionField) => {
      const existingConnectionSource = currentFilterConnectionSources?.[connectionField.key];

      // If there is already a connection source for this field, we keep it; if not, we assign the default one
      filterConnectionSources = {
        ...filterConnectionSources,
        [connectionField.key]: existingConnectionSource || defaultConnectionSource
      };
    });

    return filterConnectionSources;
  };

  const formatRestrictedConnectionSourceGroup = (
    key: KnackFieldKey,
    filterConnectionSources: BuilderViewFilterConnectionSources
  ) => ({
    key,
    source: filterConnectionSources?.[key].source,
    filters: filterConnectionSources?.[key].filters
  });

  return {
    restrictedConnectionFields,
    getDefaultFilterConnectionSources,
    getFilterConnectionSources,
    formatRestrictedConnectionSourceGroup,
    getReportRestrictedConnectionFields
  };
}
