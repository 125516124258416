import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { type WorkBook } from 'xlsx';
import * as XLSX from 'xlsx';

import { AdvancedOptions } from '@/components/import/advanced-options/AdvancedOptions';
import { ConfirmImport } from '@/components/import/ConfirmImport';
import { MapColumns } from '@/components/import/MapColumns';
import { PreviewTable } from '@/components/import/PreviewTable';
import { type ColumnMeta, type RawData } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';
import { ResizableCollapsiblePanel } from '@/components/layout/ResizableCollapsiblePanel';
import { ImportStep } from './types';
import {
  checkMatchingColumnName,
  getColumnTypes,
  getDefaultSubFieldParts,
  isReadOnlyField
} from './utils';

type ImportProps = {
  workbook: WorkBook;
  file: File | google.picker.DocumentObject;
  step?: ImportStep;
};

function TableCell({ getValue }: { getValue: () => unknown }) {
  const sanitizedValue = DOMPurify.sanitize(getValue() as string);

  return <span dangerouslySetInnerHTML={{ __html: sanitizedValue }} />;
}

export function Import({ workbook, file, step = ImportStep.FieldMapping }: ImportProps) {
  const [t] = useTranslation();
  const hasFirstRowBeenCheckedRef = useRef(false);

  const {
    columns,
    previewData,
    columnVisibility,
    hasHeaderRow,
    existingTable,
    setFileName,
    setPreviewData,
    setColumns,
    setColumnsVisibility,
    setTotalRecords,
    setColumnsType,
    setColumnsFormat,
    setWorkbook,
    resetImportStore,
    setIsFileLoading,
    isFileLoading
  } = useImportStore((state) => ({
    columns: state.columns,
    previewData: state.previewData,
    columnVisibility: state.columnVisibility,
    hasHeaderRow: state.hasHeaderRow,
    existingTable: state.existingTable,
    setFileName: state.setFileName,
    setPreviewData: state.setPreviewData,
    setColumns: state.setColumns,
    setColumnsFormat: state.setColumnsFormat,
    setTotalRecords: state.setTotalRecords,
    setColumnsType: state.setColumnsType,
    setWorkbook: state.setWorkbook,
    setColumnsVisibility: state.setColumnsVisibility,
    resetImportStore: state.resetImportStore,
    getSelectedSheetIndex: state.getSelectedSheetIndex,
    setIsFileLoading: state.setIsFileLoading,
    isFileLoading: state.isFileLoading
  }));

  const selectedSheetIndex = useImportStore((state) => state.selectedSheetIndex);
  const worksheet = workbook.Sheets[workbook.SheetNames[selectedSheetIndex]];

  // Get the first row of the sheet without default values
  // This is used to determine if the first row is actually part of the table or
  // just a subset of merged cells that are used as Table Name, Company name...etc
  const firstRowWithoutDefaultValues = XLSX.utils.sheet_to_json<RawData>(worksheet, {
    header: 1,
    blankrows: false,
    raw: false
  });
  const data = useMemo(
    () =>
      XLSX.utils.sheet_to_json<RawData>(worksheet, {
        header: 1,
        blankrows: false,
        defval: '',
        raw: false
      }),
    [worksheet]
  );

  useEffect(() => {
    if (!data || data.length === 0) return;
    setIsFileLoading(true);

    // Initialize preview data and filename
    const slicedData = hasHeaderRow ? data.slice(1, 51) : data.slice(0, 50);

    // Initialize column visibility and type states
    const predictedColumn = getColumnTypes(slicedData);
    let predictedColumnTypes = predictedColumn.map((column) => column.type as string);
    const predictedColumnFormats = predictedColumn.map((column) => column.format as string);

    const firstRow = data[0];
    const remainingRows = data.slice(1);

    // Remove the first row if it has fewer columns than the rest of the rows (e.g. when the first row is a Table Name, Company name...etc)
    if (
      !hasFirstRowBeenCheckedRef.current &&
      data.length > 1 &&
      firstRowWithoutDefaultValues[0].length < remainingRows[0].length
    ) {
      hasFirstRowBeenCheckedRef.current = true;
      data.shift();
    }

    if (predictedColumnTypes.length === 0) {
      predictedColumnTypes = new Array(firstRow.length).fill('short_text');
    }

    if (Object.keys(columnVisibility).length === 0 && file.name) {
      setFileName(file.name.replace(/\.[^/.]+$/, ''));
    }
    // setting all columns to visible by default when switching sheets
    const columnIndices = new Array(firstRow.length).fill(true);
    setColumnsVisibility(columnIndices);
    setColumnsType(predictedColumnTypes);
    setColumnsFormat(predictedColumnFormats);

    const writableFields = existingTable?.fields.filter((field) => !isReadOnlyField(field.type));

    // Initialize column format for preview table
    const formattedColumns = firstRow.map((columnValue, index) => ({
      // If the first row is already a header row, we want to use those values.
      // Otherwise, we show a generic name for the column header.
      header:
        hasHeaderRow && columnValue
          ? columnValue
          : t('components.add_table.column_n', { index: index + 1 }),
      accessorKey: index.toString(),
      meta: {
        newFieldType: predictedColumnTypes[index],
        newFieldFormat: predictedColumnFormats[index],

        existingKnackField: existingTable
          ? checkMatchingColumnName(columnValue, writableFields)
          : undefined,
        parts: getDefaultSubFieldParts(
          existingTable
            ? checkMatchingColumnName(columnValue, writableFields)?.type
            : predictedColumnTypes[index]
        ),
        isThisColumnMapped: false,
        mappedColumnIndex: undefined
      } as ColumnMeta,
      cell: TableCell
    }));
    setColumns(formattedColumns);
    setPreviewData(slicedData);
    setTotalRecords(hasHeaderRow ? data.length - 1 : data.length);
    setIsFileLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hasHeaderRow, selectedSheetIndex]);

  useEffect(() => {
    setWorkbook(workbook);
  }, [setWorkbook, workbook]);

  useEffect(
    () => () => {
      // Set the store to initial state on component unmount
      resetImportStore();
    },
    [resetImportStore]
  );

  if (isFileLoading) {
    return null;
  }

  if (step === ImportStep.ConfirmImport) {
    return <ConfirmImport />;
  }

  return (
    <ResizableCollapsiblePanel
      title=""
      panelContent={step === ImportStep.FieldMapping ? <MapColumns /> : <AdvancedOptions />}
      mainContent={<PreviewTable columns={columns} data={previewData} />}
      autoSaveId="import-panel"
    />
  );
}
