import { DateTime } from 'luxon';

import {
  type DateFormatValue,
  type DateTimeFieldFormat,
  type TimerField
} from '@/types/schema/fields';
import { formatTime } from '@/components/data-table/display/fields/date-time/formatters/timeFormatter';
import {
  type DateTimeField,
  type DurationField
} from '@/components/data-table/display/fields/Field';

function mapDateFormatFromKnackSchema(dateFormat?: DateFormatValue) {
  switch (dateFormat) {
    case 'Ignore Date':
      return undefined;

    case 'dd/mm/yyyy':
      return 'dd/MM/yyyy';

    case 'M D, yyyy':
      return 'MMMM dd, yyyy';

    case 'mm/dd/yyyy':
      return 'MM/dd/yyyy';

    default:
      return 'MM/dd/yyyy';
  }
}

function formatDate(date: string, format?: DateFormatValue) {
  if (!date || format === 'Ignore Date') return null;

  return DateTime.fromISO(date, {
    // IMPORTANT: We don't take into consideration the user Timezone and force it to UTC.
    // The timezones were not handled by the server/frontend, now the frontend use the iso_timestamp and send it back to the server.
    // This way if in the future the server could save the iso_timestamp we send and remove any forced timezone we do in the code.
    zone: 'utc'
  }).toFormat(mapDateFormatFromKnackSchema(format) || 'MM/dd/yyyy');
}

// This function was picked up from the server, typed, removed moment and simplified
// https://github.com/knackhq/Server/blob/2b83776119bcf01f20a6a3651ce046be9128a4f5/app/lib/formatter.js#L505-L506
export function formatDateTimeRawValue(
  val: DateTimeField['rawValue'],
  format: DateTimeFieldFormat
) {
  if (!val || !val.iso_timestamp) {
    return '';
  }

  let result = '';

  const date = formatDate(val.iso_timestamp, format.date_format);
  if (format.date_format !== 'Ignore Date') {
    result += date;
  }

  if (!val.all_day && format.time_format && format.time_format !== 'Ignore Time') {
    const time = formatTime(val.iso_timestamp, format.time_format);
    result += ` ${time}`;
  }

  if (val.to && val.to.iso_timestamp) {
    const dateTo = formatDate(val.to.iso_timestamp, format.date_format);

    if (date !== dateTo) {
      result += ` to ${dateTo}`;
    }

    if (!val.to.all_day && format.time_format) {
      const time = formatTime(val.to.iso_timestamp, format.time_format);
      if (date === dateTo) {
        result += ` to`;
      }
      result += ` ${time}`;
    }
  }

  return result;
}

export const getTimeInBetween = (
  dateTime: DurationField['rawValue'],
  totalFormat: TimerField['format']['total_format']
) => {
  if (!dateTime.times) {
    return '';
  }

  const times = dateTime.times[0];

  if (!times.from.iso_timestamp || !times.to.iso_timestamp) {
    return '';
  }

  const from = DateTime.fromISO(times.from.iso_timestamp);
  const to = DateTime.fromISO(times.to.iso_timestamp);

  if (totalFormat === 'from-to') return '';

  const diff = to.diff(from, totalFormat);

  return ` = ${diff.toHuman({ maximumFractionDigits: 2 })}`;
};

export const formatTimerRawValue = (
  dateTime: DurationField['rawValue'],
  format: TimerField['format']
) => {
  if (!format) return '';

  const transformedTimesToDateRawValue = {
    ...(dateTime.times && dateTime.times.length > 0 && dateTime.times[0].from),
    to: (dateTime.times && dateTime.times.length > 0 && dateTime.times[0].to) || undefined
  };

  return `${formatDateTimeRawValue(transformedTimesToDateRawValue, format)}${getTimeInBetween(dateTime, format.total_format || 'hours')}`;
};
