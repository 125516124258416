import { useEffect } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiX as XIcon } from 'react-icons/hi';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, Input, Label, RadioGroup, RichTextEditor } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { FieldSelect } from '@/components/FieldSelect';
import { DateTimeInput } from '@/components/import/confirm-import/date-time/DateTimeInput';
import { getDefaultDatePayload } from '@/components/import/confirm-import/date-time/helpers';
import { defaultDateFormatMap } from '@/components/import/confirm-import/date-time/types';
import { getDefaultTimerData } from '@/components/import/confirm-import/timer/helpers';
import { TimerInput } from '@/components/import/confirm-import/timer/TimerInput';
import { type DefaultValue } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';
import { isReadOnlyField } from '@/components/import/utils';
import { AddressInput } from '@/components/inputs/address/AddressInput';
import { BooleanInput } from '@/components/inputs/BooleanInput';
import { ConnectionInput } from '@/components/inputs/ConnectionInput';
import { EmailInput } from '@/components/inputs/EmailInput';
import { FileInput } from '@/components/inputs/FileInput';
import { LinkInput } from '@/components/inputs/LinkInput';
import { MultipleChoiceInput } from '@/components/inputs/MultipleChoiceInput';
import { NumberInput } from '@/components/inputs/NumberInput';
import { PersonInput } from '@/components/inputs/PersonInput';
import { PhoneInput } from '@/components/inputs/PhoneInput';
import { RatingInput } from '@/components/inputs/RatingInput';
import { SignatureInput } from '@/components/inputs/SignatureInput';
import { HEADER_HEIGHT } from '@/components/layout/Header';
import { TextTooltip } from '@/components/TextTooltip';

export function getDefaultValue(field: KnackField) {
  if (field.type === 'date_time') {
    const defaultDateFormat = field.format.date_format || 'mm/dd/yyyy';
    const dateFormat = defaultDateFormatMap[defaultDateFormat];
    const defaultValue = getDefaultDatePayload(
      field.format,
      dateFormat,
      field.format.calendar || false
    );

    return defaultValue;
  }

  if (field.type === 'timer') {
    const defaultValue = getDefaultTimerData(field.format);

    return defaultValue;
  }

  return '';
}

export function DefaultValueInput({
  selectedFieldKey,
  fields,
  index
}: {
  selectedFieldKey: string;
  fields: KnackField[];
  index: number;
}) {
  const [t] = useTranslation();
  const { register, setValue } = useFormContext();

  const targetField = fields.find((field) => field.key === selectedFieldKey);
  const fieldType = targetField?.type;

  const inputHtmlId = `${targetField?.key}-${fieldType}-input`;

  if (fieldType === 'address') {
    return (
      <AddressInput
        id={inputHtmlId}
        targetField={targetField}
        name={`defaultValues.${index}.value`}
      />
    );
  }
  if (fieldType === 'connection') {
    return (
      <ConnectionInput id={inputHtmlId} field={targetField} name={`defaultValues.${index}.value`} />
    );
  }
  if (fieldType === 'boolean') {
    return (
      <BooleanInput
        id={inputHtmlId}
        targetField={targetField}
        name={`defaultValues.${index}.value`}
      />
    );
  }
  if (fieldType === 'multiple_choice') {
    return (
      <MultipleChoiceInput
        id={inputHtmlId}
        targetField={targetField}
        name={`defaultValues.${index}.value`}
      />
    );
  }
  if (fieldType === 'signature') {
    return <SignatureInput name={`defaultValues.${index}.value`} />;
  }
  if (fieldType === 'currency') {
    return (
      <Input
        id={inputHtmlId}
        data-testid="default-value-input-currency"
        placeholder={t('components.add_into_existing_table.enter_currency')}
        {...register(`defaultValues.${index}.value`)}
      />
    );
  }
  if (fieldType === 'email') {
    return (
      <EmailInput
        id={inputHtmlId}
        targetField={targetField}
        name={`defaultValues.${index}.value`}
      />
    );
  }
  if (fieldType === 'link') {
    return (
      <LinkInput id={inputHtmlId} targetField={targetField} name={`defaultValues.${index}.value`} />
    );
  }
  if (fieldType === 'file' || fieldType === 'image') {
    return <FileInput name={`defaultValues.${index}.value`} targetField={targetField} />;
  }
  if (fieldType === 'name') {
    return (
      <PersonInput
        id={inputHtmlId}
        targetField={targetField}
        name={`defaultValues.${index}.value`}
      />
    );
  }
  if (fieldType === 'rich_text') {
    return (
      <RichTextEditor
        data-testid="default-value-rich-text"
        onUpdate={({ editor }) => {
          const content = editor.isEmpty ? '' : editor.getHTML();
          setValue(`defaultValues.${index}.value`, content);
        }}
      />
    );
  }
  if (fieldType === 'phone') {
    return (
      <PhoneInput
        id={inputHtmlId}
        targetField={targetField}
        name={`defaultValues.${index}.value`}
      />
    );
  }
  if (fieldType === 'rating') {
    return <RatingInput targetField={targetField} name={`defaultValues.${index}.value`} />;
  }
  if (fieldType === 'date_time') {
    return (
      <DateTimeInput
        id={inputHtmlId}
        targetField={targetField}
        name={`defaultValues.${index}.value`}
      />
    );
  }

  if (fieldType === 'timer') {
    return (
      <TimerInput
        id={inputHtmlId}
        targetField={targetField}
        name={`defaultValues.${index}.value`}
      />
    );
  }

  if (fieldType === 'number') {
    return (
      <NumberInput
        id={inputHtmlId}
        name={`defaultValues.${index}.value`}
        format={targetField.format}
      />
    );
  }

  return (
    <Input
      id={inputHtmlId}
      data-testid="default-value-input"
      placeholder={t('components.add_into_existing_table.enter_value')}
      {...register(`defaultValues.${index}.value`)}
    />
  );
}

export function DefaultValues({
  fields,
  setDefaultValues
}: {
  fields: KnackField[];
  setDefaultValues: (defaultValues: DefaultValue[]) => void;
}) {
  const [t] = useTranslation();
  const defaultValueItemSchema = z.object({
    field: z.custom<KnackFieldKey>().refine((key) => !key, { message: t('errors.value_required') }),
    type: z.string().min(1),
    input: z.string().optional(),
    connection_field: z.string().optional(),
    value: z
      .union([z.string(), z.number(), z.boolean(), z.array(z.string()), z.object({})])
      .optional()
  });

  const defaultValuesSchema = z.array(defaultValueItemSchema);

  type DefaultValuesSchema = {
    defaultValues: DefaultValue[];
  };
  const formMethods = useForm<DefaultValuesSchema>({
    resolver: zodResolver(defaultValuesSchema),
    defaultValues: { defaultValues: [] }
  });

  const { control, watch, resetField } = formMethods;

  const { remove, append } = useFieldArray({
    control,
    name: 'defaultValues'
  });

  const nonReadOnlyFields = fields.filter(({ type }) => !isReadOnlyField(type));

  const formWatch = watch();

  const handleAddDefault = () => {
    if (nonReadOnlyFields.length === 0) return;
    append({
      field: nonReadOnlyFields[0].key,
      type: 'value',
      input: '',
      connection_field: '',
      value: ''
    });
  };

  useEffect(() => {
    setDefaultValues(formWatch.defaultValues);
  }, [formWatch, setDefaultValues]);

  return (
    <div
      className="margin-bottom-double mb-6 mt-6 space-y-4 rounded-lg bg-muted p-6"
      data-testid="default-values-card"
    >
      <div>
        <Label>{t('components.add_into_existing_table.optional')}</Label>
        <h2 className="font-semibold text-emphasis">
          {t('components.add_into_existing_table.set_default')}
        </h2>
      </div>

      {formWatch.defaultValues.length > 0 && (
        <FormProvider {...formMethods}>
          {formWatch.defaultValues.map((defaultValue, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Form.Section key={index}>
              <Form.Label htmlFor="default-value-field-select-key">{t('actions.set')}</Form.Label>
              <div className="flex justify-between">
                <div className="max-w-96 flex-1 space-y-2">
                  <Controller
                    control={control}
                    name={`defaultValues.${index}.field`}
                    render={({ field }) => (
                      <FieldSelect
                        id="default-values-field-select-key"
                        data-testid="default-values-field-select-value"
                        fields={nonReadOnlyFields}
                        defaultValue={field.value || nonReadOnlyFields[0].key}
                        onFieldChange={(selectedField) => {
                          resetField(`defaultValues.${index}.value`, {
                            defaultValue: getDefaultValue(
                              fields.find((f) => f.key === selectedField) as KnackField
                            )
                          });
                          field.onChange(selectedField);
                        }}
                        {...field}
                      />
                    )}
                  />
                  <Form.Label htmlFor="default-value-field-select-value">
                    {t('actions.to')}
                  </Form.Label>
                  <DefaultValueInput
                    selectedFieldKey={watch(`defaultValues.${index}.field`)}
                    fields={fields}
                    index={index}
                  />
                </div>
                <div>
                  <Button
                    intent="minimal"
                    onClick={handleAddDefault}
                    disabled={nonReadOnlyFields.length === 0}
                    data-testid={`add-default-value-plus-button-${index}`}
                  >
                    <PlusIcon size="16" />
                  </Button>
                  <Button
                    intent="minimal"
                    onClick={() => remove(index)}
                    data-testid={`remove-default-value-plus-button-${index}`}
                  >
                    <XIcon size="16" />
                  </Button>
                </div>
              </div>
            </Form.Section>
          ))}
        </FormProvider>
      )}
      {formWatch.defaultValues.length === 0 && (
        <div>
          <TextTooltip label={t('components.add_into_existing_table.no_non_read_fields')}>
            <Button
              intent="secondary"
              data-testid="add-default-value-button"
              onClick={handleAddDefault}
              disabled={nonReadOnlyFields.length === 0}
            >
              <PlusIcon className="mr-2" size="16" />
              {t('components.add_into_existing_table.add_a_default_value')}
            </Button>
          </TextTooltip>
        </div>
      )}
    </div>
  );
}

export function ConfirmImport() {
  const {
    fileName,
    totalRecords,
    setShouldSkipRecordsWithErrors,
    existingTable,
    setDefaultValues
  } = useImportStore((state) => ({
    fileName: state.fileName,
    totalRecords: state.totalRecords,
    setShouldSkipRecordsWithErrors: state.setShouldSkipRecordsWithErrors,
    existingTable: state.existingTable,
    setDefaultValues: state.setDefaultValues
  }));
  const [t] = useTranslation();

  const fields = existingTable?.fields || [];

  const handleErrorHandlingOptions = (option: string) => {
    setShouldSkipRecordsWithErrors(option === 'skip');
  };
  const tableToImport = existingTable ? existingTable.name : fileName;

  return (
    <div
      className="-mt-7 flex w-full justify-center overflow-auto pt-12"
      style={{ height: `calc(100vh - ${HEADER_HEIGHT * 2}px)` }}
    >
      <div className="max-w-lg" data-testid="import-confirm-paragraph">
        <h3 className="block text-xl font-medium text-emphasis">
          {t('components.add_table.confirm_your_import')}
        </h3>
        <p className="mt-2 block" data-testid="confirm-table-name">
          <Trans
            i18nKey="components.add_table.you_are_about_to_import"
            values={{ totalRecords, tableToImport }}
            components={[
              <span key="totalRecords" className="font-semibold" />,
              <span key="fileName" className="font-semibold" />
            ]}
          />
        </p>
        <Label htmlFor="error-handling-options" className="mt-6 block font-medium">
          {t('components.add_table.error_handling')}
        </Label>

        <RadioGroup
          defaultValue="blank"
          onValueChange={handleErrorHandlingOptions}
          className="mt-1 flex flex-col"
          id="error-handling-options"
          data-testid="error-handling-options"
        >
          <RadioGroup.Container>
            <RadioGroup.Item value="blank" id="radio-item-leave" />
            <Label htmlFor="radio-item-leave">{t('components.add_table.blank')}</Label>
          </RadioGroup.Container>
          <RadioGroup.Container>
            <RadioGroup.Item value="skip" id="radio-item-skip" />
            <Label htmlFor="radio-item-skip">{t('components.add_table.skip')}</Label>
          </RadioGroup.Container>
        </RadioGroup>
        {existingTable && <DefaultValues fields={fields} setDefaultValues={setDefaultValues} />}
      </div>
    </div>
  );
}
