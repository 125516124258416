import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronUpDown as ChevronUpDownIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Collapsible } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';

import { type BuilderViewActionRule } from '@/types/schema/BuilderView';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { useDndUtils } from '@/hooks/useDndUtils';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { DynamicActionsDialog } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/dynamic-actions/DynamicActionsDialog';
import { ViewDynamicActionCard } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/dynamic-actions/ViewDynamicActionCard';

interface ViewColumnDynamicActionsProps<T extends TableViewColumn | DetailsViewInput> {
  columnItem: T;
  updateViewColumn: (data: Partial<T>) => void;
}

export function ViewColumnDynamicActions<T extends TableViewColumn | DetailsViewInput>({
  columnItem,
  updateViewColumn
}: ViewColumnDynamicActionsProps<T>) {
  const [t] = useTranslation('translation', {
    keyPrefix:
      'pages.element_settings.table.categories.data_display.field_management.dynamic_actions_column'
  });
  const { sourceObject } = useActiveViewContext<TableView | ViewWithDetails>();
  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [beingDraggedSummaryId, setBeingDraggedSummaryId] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [collapsedCardsKeys, setCollapsedCardsKeys] = useState<string[]>([]);

  const actionRules = columnItem.action_rules || [];

  // Create a fieldMap to quickly access the fields without having to iterate over sourceObject.fields every time.
  const sourceObjectFieldsMap: Record<KnackFieldKey, KnackField> = useMemo(
    () => Object.fromEntries(sourceObject.fields.map((field) => [field.key, field])),
    [sourceObject]
  );

  const onSaveDynamicAction = (data: BuilderViewActionRule) => {
    updateViewColumn({
      id: columnItem.id,
      action_rules: columnItem.action_rules ? [...columnItem.action_rules, data] : [data]
    } as Partial<T>);
  };

  const onUpdateDynamicAction = (data: BuilderViewActionRule) => {
    const updatedActionRules = columnItem.action_rules?.map((action) =>
      action.key === data.key ? data : action
    );

    updateViewColumn({
      id: columnItem.id,
      action_rules: updatedActionRules
    } as Partial<T>);
  };

  const onDuplicateDynamicAction = (data: BuilderViewActionRule) => {
    if (!columnItem.action_rules) {
      return;
    }

    updateViewColumn({
      id: columnItem.id,
      action_rules: [...columnItem.action_rules, { ...data, key: `action_${nanoid(10)}` }]
    } as Partial<T>);
  };

  const onDeleteDynamicAction = (index: number) => {
    const updatedActionRules = columnItem.action_rules?.filter((_, i) => i !== index - 1);

    updateViewColumn({
      id: columnItem.id,
      action_rules: updatedActionRules
    } as Partial<T>);
  };

  const handleCollapseCard = (open: boolean, key: string) => {
    setCollapsedCardsKeys((prev) => {
      if (!open) {
        return [...prev, key];
      }

      return prev.filter((item) => item !== key);
    });
  };

  const handleCollapseAllCards = () => {
    const actionRulesKeys = columnItem?.action_rules?.map((action) => action.key) || [];
    setCollapsedCardsKeys(actionRulesKeys);
  };

  const handleExpandAllCards = () => {
    setCollapsedCardsKeys([]);
  };

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedSummaryId(event.active.id.toString());
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!actionRules) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = actionRules.findIndex((sum) => sum.key === active.id) ?? -1;
      const newIndex = actionRules.findIndex((sum) => sum.key === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const newArray = arrayMove(actionRules, oldIndex, newIndex);

      updateViewColumn({
        id: columnItem.id,
        action_rules: newArray
      } as Partial<T>);

      setBeingDraggedSummaryId(null);
    }
  };

  return (
    <BuilderAccordion.Item isDefaultOpen label={t('label')}>
      <div className="rounded-lg border">
        <p className="p-2 text-xs">{t('info_banner')}</p>
      </div>
      <Button
        data-testid="add-dynamic-action"
        intent="secondary"
        className="mt-2"
        onClick={() => setIsDialogOpen(true)}
      >
        <PlusIcon size={16} className="mr-1" />
        {t('dialog_trigger')}
      </Button>

      {actionRules.length > 0 && (
        <div className="mt-2 flex gap-1">
          <Button
            size="sm"
            intent="minimal"
            onClick={handleExpandAllCards}
            className="text-xs underline"
          >
            <Button.Icon icon={ChevronUpDownIcon} className="mr-1" />
            {t('expand_all')}
          </Button>
          <Button
            size="sm"
            intent="minimal"
            onClick={handleCollapseAllCards}
            className="text-xs underline"
          >
            <Button.Icon icon={ChevronUpDownIcon} className="mr-1" />
            {t('collapse_all')}
          </Button>
        </div>
      )}

      <DndContext
        sensors={optimizedSensors}
        collisionDetection={verticalListCollisionDetection}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={actionRules.map((sum) => sum.key)}
          strategy={verticalListSortingStrategy}
        >
          {actionRules.map((action, actionIndex) => (
            <VerticalListSortableItem key={action.key} id={action.key}>
              <Collapsible
                key={action.key}
                open={!collapsedCardsKeys.includes(action.key)}
                onOpenChange={(open) => {
                  handleCollapseCard(open, action.key);
                }}
              >
                <ViewDynamicActionCard
                  dynamicAction={action}
                  dynamicActionNumber={actionIndex + 1}
                  sourceObject={sourceObject}
                  sourceObjectFieldsMap={sourceObjectFieldsMap}
                  onActionSave={onUpdateDynamicAction}
                  onActionDuplicate={onDuplicateDynamicAction}
                  onActionDelete={onDeleteDynamicAction}
                />
              </Collapsible>
            </VerticalListSortableItem>
          ))}
        </SortableContext>
        <DragOverlay>
          {actionRules.map((action, actionIndex) => {
            if (action.key !== beingDraggedSummaryId) {
              return null;
            }

            return (
              <VerticalListSortableItem key={action.key} id={action.key}>
                <Collapsible
                  key={action.key}
                  open={!collapsedCardsKeys.includes(action.key)}
                  onOpenChange={(open) => {
                    handleCollapseCard(open, action.key);
                  }}
                >
                  <ViewDynamicActionCard
                    dynamicAction={action}
                    dynamicActionNumber={actionIndex + 1}
                    sourceObject={sourceObject}
                    sourceObjectFieldsMap={sourceObjectFieldsMap}
                    onActionSave={onUpdateDynamicAction}
                    onActionDuplicate={onDuplicateDynamicAction}
                    onActionDelete={onDeleteDynamicAction}
                  />
                </Collapsible>
              </VerticalListSortableItem>
            );
          })}
        </DragOverlay>
      </DndContext>
      {isDialogOpen && (
        <DynamicActionsDialog
          sourceObject={sourceObject}
          onUpdate={onSaveDynamicAction}
          onOpenChange={setIsDialogOpen}
        />
      )}
    </BuilderAccordion.Item>
  );
}
