import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Divider } from '@knack/asterisk-react';

import { type BuilderPageRule } from '@/types/schema/BuilderPage';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { useDndUtils } from '@/hooks/useDndUtils';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { LearnMoreLink } from '@/components/LearnMoreLink';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { PageRuleCard } from '@/pages/pages/settings-panel/page-settings/page-rules/PageRuleCard';
import { usePageRuleHelpers } from '@/pages/pages/settings-panel/page-settings/page-rules/usePageRuleHelpers';
import { PageRuleForm } from './PageRuleForm';
import { PageRuleFormDialog } from './PageRuleFormDialog';

type NewlyInsertedRecordRule = {
  key: string;
  insertAction: 'new' | 'duplicate';
};

type PageRuleInModal = {
  pageRule: BuilderPageRule;
  modalIntent: 'add' | 'edit';
};

export function PageRules() {
  const [t] = useTranslation();

  const { page, updatePage } = usePageEditorContext();
  const { getPageSourceObject } = usePageHelpers();
  const { getPageRuleCriteriaFieldOptions, getDefaultPageRule } = usePageRuleHelpers();
  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [pageRuleInModal, setPageRuleInModal] = useState<PageRuleInModal | null>(null);
  const [newlyInsertedRule, setNewlyInsertedRule] = useState<NewlyInsertedRecordRule | null>(null);
  const [beingDraggedRuleKey, setBeingDraggedRuleKey] = useState<string | null>(null);

  const pageSourceObject = getPageSourceObject(page);
  const pageRuleCriteraFieldOptions = getPageRuleCriteriaFieldOptions(page, pageSourceObject);

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedRuleKey(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!page.rules) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = page.rules.findIndex((rule) => rule.key === active.id) ?? -1;
      const newIndex = page.rules.findIndex((rule) => rule.key === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const reorderedRules = arrayMove(page.rules, oldIndex, newIndex);

      updatePage({
        type: 'page',
        action: 'update',
        pageUpdates: {
          rules: reorderedRules
        }
      });

      setBeingDraggedRuleKey(null);
    }
  };

  const onRuleAdd = (newPageRule: BuilderPageRule) => {
    setNewlyInsertedRule({
      key: newPageRule.key,
      insertAction: 'new'
    });

    updatePage({
      type: 'page',
      action: 'update',
      pageUpdates: {
        rules: page.rules ? [...page.rules, newPageRule] : [newPageRule]
      }
    });
  };

  const onRuleSave = (updatedPageRule: BuilderPageRule) => {
    if (!page.rules) {
      return;
    }

    updatePage({
      type: 'page',
      action: 'update',
      pageUpdates: {
        rules: page.rules.map((pageRule) =>
          pageRule.key === updatedPageRule.key ? updatedPageRule : pageRule
        )
      }
    });
  };

  const onRuleDuplicate = (pageRuleToDuplicate: BuilderPageRule) => {
    if (!page.rules) {
      return;
    }

    const newPageRule = getDefaultPageRule(pageRuleCriteraFieldOptions, pageSourceObject);
    const duplicatedRule: BuilderPageRule = {
      ...pageRuleToDuplicate,
      key: newPageRule.key
    };

    setNewlyInsertedRule({
      key: duplicatedRule.key,
      insertAction: 'duplicate'
    });

    updatePage({
      type: 'page',
      action: 'update',
      pageUpdates: {
        rules: [...page.rules, duplicatedRule]
      }
    });
  };

  const onRuleDelete = (recordRuleKey: RecordRule['key']) => {
    updatePage({
      type: 'page',
      action: 'update',
      pageUpdates: {
        rules: page.rules?.filter((recordRule) => recordRule.key !== recordRuleKey)
      }
    });
  };

  return (
    <>
      <Divider className="my-4" />
      <p className="mb-4 text-xs text-subtle">
        <Trans
          i18nKey="pages.settings.rules.description"
          components={[
            <LearnMoreLink key="0" href="https://learn.knack.com/article/zplf1gfk5m-page-rules" />
          ]}
        />
      </p>

      <Button
        intent="secondary"
        className="w-full"
        onClick={() =>
          setPageRuleInModal({
            modalIntent: 'add',
            pageRule: getDefaultPageRule(pageRuleCriteraFieldOptions, pageSourceObject)
          })
        }
        data-testid="add-page-rule-button"
      >
        <PlusIcon size={16} className="mr-2" />
        {t('pages.settings.rules.add_rule')}
      </Button>

      {page.rules && page.rules.length > 0 && (
        <DndContext
          sensors={optimizedSensors}
          collisionDetection={verticalListCollisionDetection}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={page.rules.map((rule) => rule.key)}
            strategy={verticalListSortingStrategy}
          >
            <div className="mt-4 space-y-2">
              {page.rules.map((pageRule, ruleIndex) => (
                <VerticalListSortableItem key={pageRule.key} id={pageRule.key}>
                  <PageRuleCard
                    key={pageRule.key}
                    pageRule={pageRule}
                    pageRuleCriteriaFieldOptions={pageRuleCriteraFieldOptions}
                    pageSourceObject={pageSourceObject}
                    shouldScrollIntoView={newlyInsertedRule?.key === pageRule.key}
                    ruleNumber={ruleIndex + 1}
                    onRuleEdit={() => setPageRuleInModal({ modalIntent: 'edit', pageRule })}
                    onRuleDuplicate={onRuleDuplicate}
                    onRuleDelete={onRuleDelete}
                  />
                </VerticalListSortableItem>
              ))}
            </div>
          </SortableContext>

          <DragOverlay>
            {page.rules.map((pageRule, ruleIndex) => {
              if (pageRule.key !== beingDraggedRuleKey) {
                return null;
              }

              return (
                <PageRuleCard
                  key={pageRule.key}
                  isDragOverlay
                  pageRule={pageRule}
                  pageRuleCriteriaFieldOptions={pageRuleCriteraFieldOptions}
                  pageSourceObject={pageSourceObject}
                  shouldScrollIntoView={newlyInsertedRule?.key === pageRule.key}
                  ruleNumber={ruleIndex + 1}
                  onRuleEdit={() => setPageRuleInModal({ modalIntent: 'edit', pageRule })}
                  onRuleDuplicate={onRuleDuplicate}
                  onRuleDelete={onRuleDelete}
                />
              );
            })}
          </DragOverlay>
        </DndContext>
      )}

      {pageRuleInModal && (
        <PageRuleFormDialog
          formId="page-rule-form"
          formIntent={pageRuleInModal.modalIntent}
          onOpenChange={() => setPageRuleInModal(null)}
        >
          <PageRuleForm
            formId="page-rule-form"
            pageRule={pageRuleInModal.pageRule}
            pageRuleCriteriaFieldOptions={pageRuleCriteraFieldOptions}
            pageSourceObject={pageSourceObject}
            onSubmit={(modifiedPageRule) => {
              setPageRuleInModal(null);

              if (pageRuleInModal.modalIntent === 'edit') {
                onRuleSave(modifiedPageRule);
              }

              if (pageRuleInModal.modalIntent === 'add') {
                onRuleAdd(modifiedPageRule);
              }
            }}
          />
        </PageRuleFormDialog>
      )}
    </>
  );
}
