import { type ReturnDate } from '@knack/asterisk-react/dist/src/components/date-time-picker/components/DateTimeInput/DateTimeInput';
import { type DateRangeWithErrors } from '@knack/asterisk-react/dist/src/components/date-time-picker/DateTimePicker';
import { DateTime } from 'luxon';

import { type DateTimeFieldFormat } from '@/types/schema/fields/DateTimeField';
import { type DateTimeField } from '@/components/data-table/display/fields/Field';
import { knackDateFormatToDatePickerMap } from '@/components/knack-date-picker/KnackDatePicker';

export function isDate(date: ReturnDate): date is Date {
  return date instanceof Date;
}

export function JsDateSingleToKnackDateTransformer(
  date: ReturnDate,
  fieldSettings: DateTimeFieldFormat
): DateTimeField['rawValue'] {
  if (!isDate(date)) {
    return {};
  }

  // IMPORTANT: We "break" the UTC time zone here to make sure the date is not converted to the local time zone
  // Return to normal when the time zone is fixed in the server
  const luxonDate = DateTime.fromJSDate(date).setZone('utc', { keepLocalTime: true });
  // const luxonDate = DateTime.fromJSDate(date);

  const dateFormat = knackDateFormatToDatePickerMap[fieldSettings.date_format || 'MM/dd/yyyy'];

  return {
    iso_timestamp: luxonDate.toISO() || '',
    date: dateFormat ? luxonDate.toFormat(dateFormat) : undefined,
    date_formatted: luxonDate.toFormat('MM/dd/yyyy HH:mm'),
    // Check 00 / 12 hours when military
    hours: luxonDate.toFormat('HH'),
    minutes: luxonDate.toFormat('mm'),
    am_pm: luxonDate.toFormat('a') as 'AM' | 'PM' | undefined
  };
}

export function JsDateRangeToKnackDateTransformer(
  date: DateRangeWithErrors | undefined,
  fieldSettings: DateTimeFieldFormat
): DateTimeField['rawValue'] {
  return {
    ...JsDateSingleToKnackDateTransformer(date?.from || undefined, fieldSettings),
    to: JsDateSingleToKnackDateTransformer(date?.to || undefined, fieldSettings)
  };
}

function isDateRangeWithErrorsGuard(
  date: DateRangeWithErrors | ReturnDate
): date is DateRangeWithErrors {
  return !!date && (date as DateRangeWithErrors).from !== undefined;
}

export function JsDateToKnackDateTransformer(
  date: DateRangeWithErrors | ReturnDate,
  fieldSettings: DateTimeFieldFormat
): DateTimeField['rawValue'] {
  if (isDateRangeWithErrorsGuard(date)) {
    return JsDateRangeToKnackDateTransformer(date, fieldSettings);
  }

  if (isDate(date)) {
    return JsDateSingleToKnackDateTransformer(date, fieldSettings);
  }

  return {};
}
