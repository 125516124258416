import { type ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowLeft as BackIcon } from 'react-icons/hi2';
import { Button, Divider } from '@knack/asterisk-react';

interface CollapsiblePanelContentProps extends ComponentPropsWithoutRef<'div'> {
  title: string;
  onBack?: () => void;
  shouldHideDivider?: boolean;
  children: React.ReactNode;
  footer?: React.ReactNode;
  backTitle?: string;
}

export function CollapsiblePanelContent({
  title,
  onBack,
  shouldHideDivider = false,
  children,
  footer,
  backTitle,
  ...props
}: CollapsiblePanelContentProps) {
  const [t] = useTranslation();

  return (
    <div className="flex grow flex-col overflow-hidden" {...props}>
      <div className="p-4" data-testid="collapsible-panel-header-section">
        {onBack && (
          <Button
            intent="minimalStandalone"
            className="mb-2 flex h-auto p-0 text-xs font-normal text-default"
            onClick={onBack}
          >
            <BackIcon size={16} className="mr-1" />
            {backTitle || t('actions.back')}
          </Button>
        )}
        <h2 className="font-semibold text-emphasis" data-testid="collapsible-panel-header-title">
          {title}
        </h2>
      </div>
      <div className="relative grow">
        <div className="absolute inset-0 overflow-y-auto px-4 pb-6">
          {!shouldHideDivider && <Divider className="mb-4" />}
          {children}
        </div>
      </div>
      {footer}
    </div>
  );
}
