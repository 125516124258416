import { useId } from 'react';
import { type FieldErrors, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label, RadioGroup, Select } from '@knack/asterisk-react';

import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { InlineCheckboxCardGroup } from '@/components/InlineCheckboxCardGroup';
import { WEEK_DAYS } from '@/components/knack-date-picker/transformers/KnackWeekDaysTransformer';

interface RepeatsEveryProps {
  repeatValue: FieldValues;
  onChange: (value: FieldValues | null) => void;
  errors: FieldErrors;
}

export function RepeatsEvery({ repeatValue, onChange, errors }: RepeatsEveryProps) {
  const id = useId();
  const [t] = useTranslation();

  if (!repeatValue) return null;

  return (
    <div className="mt-5 flex flex-col gap-2">
      <Label>{t('date_picker.repeats_every')}</Label>
      <div className="flex gap-2">
        <Input
          type="number"
          value={repeatValue.interval}
          onChange={(event) => {
            onChange({
              ...repeatValue,
              interval: event.target.value
            });
          }}
          className="max-w-20"
        />
        <Select
          value={repeatValue?.frequency || 'daily'}
          onValueChange={(frequency) =>
            onChange({
              ...repeatValue,
              frequency
            })
          }
        >
          <Select.Trigger placeholder="Select a frequency" value="daily" className="min-w-36" />
          <Select.Content>
            <Select.Group>
              <Select.Item value="daily">{t('date_picker.days')}</Select.Item>
              <Select.Item value="weekly">{t('date_picker.weeks')}</Select.Item>
              <Select.Item value="monthly">{t('date_picker.months')}</Select.Item>
              <Select.Item value="yearly">{t('date_picker.years')}</Select.Item>
            </Select.Group>
          </Select.Content>
        </Select>
      </div>

      <FormErrorMessage className="mt-1" errors={errors} name="repeat.interval" />

      {repeatValue?.frequency === 'weekly' && (
        <div>
          <Label>{t('date_picker.on')}</Label>
          <InlineCheckboxCardGroup
            options={WEEK_DAYS.map((day) => ({
              label: t(`date_picker.week_days.${day}`),
              value: day
            }))}
            values={repeatValue.weekDays || []}
            onValueChange={(selectedWeekDays) => {
              onChange({
                ...repeatValue,
                weekDays: selectedWeekDays
              });
            }}
            itemClassName="size-10"
          />
        </div>
      )}

      {repeatValue?.frequency === 'monthly' && (
        <div>
          <Label>{t('date_picker.repeat_on')}</Label>
          <RadioGroup
            value={repeatValue.repeatby}
            onValueChange={(value) => {
              onChange({
                ...repeatValue,
                repeatby: value
              });
            }}
            className="mt-1 flex flex-col"
          >
            <RadioGroup.Container>
              <RadioGroup.Item value="dom" id={`${id}radio-dom`} />
              <Label htmlFor={`${id}radio-dom`}>{t('date_picker.day_of_the_month')}</Label>
            </RadioGroup.Container>
            <RadioGroup.Container>
              <RadioGroup.Item value="dow" id={`${id}radio-item-dow`} />
              <Label htmlFor={`${id}radio-item-dow`}>{t('date_picker.day_of_the_week')}</Label>
            </RadioGroup.Container>
          </RadioGroup>
        </div>
      )}
    </div>
  );
}
