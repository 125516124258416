import { Trans, useTranslation } from 'react-i18next';
import { HiTableCells as TableIcon } from 'react-icons/hi2';
import { Badge, Divider, Input, Label, RadioGroup } from '@knack/asterisk-react';
import { produce } from 'immer';

import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { FieldIcon } from '@/components/FieldIcon';
import { FieldInputConnectionSettings } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/FieldInputConnectionSettings';
import { LabelStyleSettings } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/LabelStyleSettings';
import { ViewWithDetailsActionColumnSettings } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/ViewWithDetailsActionColumnSettings';
import { useDetailsSettingsHelpers } from '@/pages/pages/settings-panel/view-settings/common/useDetailsSettingsHelpers';
import { useUpdateViewColumns } from '@/pages/pages/settings-panel/view-settings/useUpdateViewColumns';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';

export function ViewWithDetailsColumnSettings({
  columns
}: {
  columns: ViewWithDetails['columns'];
}) {
  const [t] = useTranslation();
  const { viewInputId } = useViewInputEditModeContext();
  const { getObjectByFieldKey } = useObjectHelpers();
  const { getFieldByKey } = useFieldHelpers();
  const { getViewDetailsInput } = useDetailsSettingsHelpers();
  const updateViewColumnsSchema = useUpdateViewColumns();

  const fieldInput = getViewDetailsInput(columns, viewInputId);

  if (!fieldInput || fieldInput.type === 'divider') {
    return null;
  }

  const columnField = getFieldByKey(fieldInput.key);
  const fieldSourceTableName = getObjectByFieldKey(fieldInput.key)?.name;

  const isActionColumn = fieldInput.type !== 'field';
  const shouldDisplayLabelStyleSettings =
    fieldInput.format?.label_custom || fieldInput.format?.styles.length > 0;
  const shouldDisplayConnectionSettings = columnField?.type === 'connection';

  const updateDetailsColumn = (updatedFieldInput: Partial<DetailsViewInput>) => {
    const updatedDetailsView = produce(columns, (draft) => {
      draft.forEach((outerColumn) => {
        outerColumn.groups.forEach((group) => {
          group.columns.forEach((column) => {
            column.forEach((input) => {
              if (input.id === fieldInput.id) {
                Object.assign(input, updatedFieldInput);
              }
            });
          });
        });
      });
    });

    updateViewColumnsSchema({ columns: updatedDetailsView });
  };

  if (isActionColumn) {
    return (
      <ViewWithDetailsActionColumnSettings
        fieldInput={fieldInput}
        updateListColumn={updateDetailsColumn}
      />
    );
  }

  return (
    <div className="mt-6">
      <div className="mb-4">
        <span className="mb-2 block">
          {t(
            'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_source'
          )}
        </span>
        <Badge className="inline-flex items-center rounded-md">
          <TableIcon size={16} name={fieldInput.type} className="mr-1 text-subtle" />
          <Trans
            i18nKey="pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_source_description"
            values={{ tableName: fieldSourceTableName, fieldName: fieldInput.name }}
          >
            <span className="mr-1 font-semibold">{fieldInput.name}</span>
            <span className="font-semibold">{fieldSourceTableName}</span>
          </Trans>
        </Badge>
      </div>
      <div>
        <span className="mb-2 block">
          {t(
            'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_type'
          )}
        </span>
        {columnField && (
          <Badge className="inline-flex items-center rounded-md">
            <FieldIcon name={columnField.type} size={16} className="mr-1" />
            {t(`attributes.field_types.${columnField.type}`)}
          </Badge>
        )}
      </div>
      <Divider className="my-6" />
      <div className="mb-4">
        <Label className="mb-2 block" htmlFor={`field-label-${fieldInput.key}`}>
          {t(
            'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_label'
          )}
        </Label>
        <Input
          id={`field-label-${fieldInput.key}`}
          value={fieldInput.name}
          onChange={(e) => updateDetailsColumn({ name: e.target.value })}
          data-testid="input-field-label"
        />
      </div>

      <Divider className="my-6" />
      <div className="mb-4">
        <Label className="mb-2 block" htmlFor={`field-label-${fieldInput.key}`}>
          {t(
            'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.label_styling'
          )}
        </Label>
        <RadioGroup
          defaultValue={shouldDisplayLabelStyleSettings ? 'custom' : 'default'}
          className="mt-1 flex flex-col"
          id="label-format-options"
          data-testid="label-format-options"
          onValueChange={(value) =>
            updateDetailsColumn({
              format: { ...fieldInput.format, label_custom: value === 'custom' }
            })
          }
        >
          <RadioGroup.Container>
            <RadioGroup.Item value="default" id="radio-item-use-default-style" />
            <Label htmlFor="radio-item-use-default-style">
              {t(
                'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.use_default'
              )}
            </Label>
          </RadioGroup.Container>
          <RadioGroup.Container>
            <RadioGroup.Item value="custom" id="radio-item-use-custom-style" />
            <Label htmlFor="radio-item-set-custom-style text-subtle">
              {t(
                'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.set_custom'
              )}
            </Label>
          </RadioGroup.Container>
        </RadioGroup>
        {shouldDisplayLabelStyleSettings && (
          <LabelStyleSettings fieldInput={fieldInput} updateListColumn={updateDetailsColumn} />
        )}
        {shouldDisplayConnectionSettings && (
          <BuilderAccordion.Item
            className="mt-4"
            label={t(
              'pages.element_settings.table.categories.data_display.field_management.connection_settings'
            )}
            isDefaultOpen
          >
            <FieldInputConnectionSettings
              fieldInput={fieldInput}
              updateDetailsColumn={updateDetailsColumn}
              connectionField={columnField}
            />
          </BuilderAccordion.Item>
        )}
      </div>
    </div>
  );
}
