import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { type DateTimePickerProps } from '@knack/asterisk-react/dist/src/components/date-time-picker/DateTimePicker';
import { DateTime } from 'luxon';
import * as z from 'zod';

import {
  type DateFormatValue,
  type DateTimeFieldFormat,
  type TimeFormatValue
} from '@/types/schema/fields/DateTimeField';
import { type DateTimeRawValue } from '@/components/data-table/display/fields/Field';
import {
  getKnackDateRepeatSchema,
  KnackDatePickerRepeatOptions
} from '@/components/knack-date-picker/components/KnackDatePickerRepeatOptions';
import { getDatePickerZodSchema } from '@/components/knack-date-picker/form/DatePickerFormSchemas';
import { DatePickerFormWrapper } from '@/components/knack-date-picker/form/DatePickerFormWrapper';
import { JsDateToKnackDateTransformer } from '@/components/knack-date-picker/transformers/JsDateToKnackDateTransformer';
import { KnackDateToJsDateTransformer } from '@/components/knack-date-picker/transformers/KnackDateToJsDateTransformer';
import {
  transformKnackWeekDayToWeekDaysArray,
  transformWeekDayArrayToKnackWeekDay
} from '@/components/knack-date-picker/transformers/KnackWeekDaysTransformer';

export const knackDateFormatToDatePickerMap: Record<
  DateFormatValue,
  DateTimePickerProps['format']['date']
> = {
  'dd/mm/yyyy': 'dd/MM/yyyy',
  'mm/dd/yyyy': 'MM/dd/yyyy',
  'M D, yyyy': 'MM/dd/yyyy',
  'Ignore Date': undefined
} as const;

export const knackTimeFormatToDatePickerMap: Record<
  TimeFormatValue,
  DateTimePickerProps['format']['time']
> = {
  'HH:MM am': 'hh:mm a',
  'HH MM (military)': 'HH:mm',
  'Ignore Time': undefined
} as const;

type KnackDatePickerProps = {
  fieldFormat: DateTimeFieldFormat;
  defaultValue: DateTimeRawValue;
  onChange: (date: DateTimeRawValue) => void;
  isRepeatDisabled?: boolean;
  isRequired?: boolean;
};

export function KnackDatePicker(props: KnackDatePickerProps) {
  const [t] = useTranslation();
  const {
    isRequired = false,
    fieldFormat,
    defaultValue,
    onChange,
    isRepeatDisabled = false
  } = props;

  const calendarMode = fieldFormat.calendar ? 'range' : 'single';

  const datePickerFormat = {
    date: knackDateFormatToDatePickerMap[fieldFormat.date_format || 'Ignore Date'],
    time: knackTimeFormatToDatePickerMap[fieldFormat.time_format || 'Ignore Date']
  };

  const datePickerValues =
    calendarMode === 'single'
      ? KnackDateToJsDateTransformer(defaultValue) || undefined
      : {
          from: KnackDateToJsDateTransformer(defaultValue) || undefined,
          to: (defaultValue.to && KnackDateToJsDateTransformer(defaultValue.to)) || undefined
        };

  const schema = z
    .object({
      date: getDatePickerZodSchema(calendarMode, datePickerFormat, t, isRequired)
    })
    .merge(getKnackDateRepeatSchema(t));

  type Schema = z.infer<typeof schema>;

  const transformDatePickerStateToKnackJsDateRawValue = useCallback(
    (state: FieldValues): DateTimeRawValue => ({
      ...JsDateToKnackDateTransformer(state.date, fieldFormat),
      all_day: state.all_day,
      repeat: {
        ...state.repeat,
        ...(state.repeat?.frequency === 'weekly' &&
          transformWeekDayArrayToKnackWeekDay(state.repeat?.weekDays)),
        ...(state.repeat?.endson === 'date' && {
          end_date: DateTime.fromJSDate(state.repeat.end_date).toFormat('MM/dd/yyyy')
        })
      } satisfies DateTimeRawValue['repeat'] // The zod schema of the form is more strict than the repeat type, so we need to cast it
    }),
    [fieldFormat]
  );

  const defaultEndDate = useMemo(() => {
    if (!defaultValue.repeat?.end_date) return undefined;
    try {
      return DateTime.fromFormat(defaultValue.repeat?.end_date, 'MM/dd/yyyy').toJSDate();
    } catch (e) {
      return undefined;
    }
  }, [defaultValue.repeat?.end_date]);

  const methods = useForm<Schema>({
    defaultValues: {
      date: datePickerValues,
      all_day: defaultValue.all_day,
      repeat: defaultValue.repeat
        ? ({
            frequency: defaultValue.repeat?.frequency || 'daily',
            interval: parseInt(defaultValue.repeat?.interval || '1', 10),
            repeatby: defaultValue.repeat?.repeatby || 'dom',
            endson: defaultValue.repeat?.endson || 'never',
            end_count: parseInt(defaultValue.repeat?.end_count || '1', 10),
            end_date: defaultEndDate,
            weekDays: transformKnackWeekDayToWeekDaysArray(
              defaultValue.repeat || {
                MO: false,
                TU: false,
                WE: false,
                TH: false,
                FR: false,
                SA: false,
                SU: false
              }
            )
          } as Partial<Schema['repeat']>) // The zod schema of the form is more strict than the repeat type, so we need to cast it
        : undefined
    },
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { watch } = methods;

  const onSubmit = useCallback(
    (state: FieldValues) => {
      onChange(transformDatePickerStateToKnackJsDateRawValue(state));
    },
    [onChange, transformDatePickerStateToKnackJsDateRawValue]
  );

  useEffect(() => {
    const subscription = watch((data) => {
      if (!data) return;

      onSubmit(data);
    });
    return () => subscription.unsubscribe();
  }, [onSubmit, watch]);

  return (
    <FormProvider {...methods}>
      <div className="w-fit">
        <DatePickerFormWrapper
          defaultValue={datePickerValues}
          mode={calendarMode}
          name="date"
          format={{
            date: datePickerFormat.date,
            time: watch('all_day') === true ? undefined : datePickerFormat.time
          }}
        />
        {!isRepeatDisabled && (
          <KnackDatePickerRepeatOptions mode={calendarMode} format={datePickerFormat} />
        )}

        {/* 
        <pre>Form: {JSON.stringify(watch(), null, 4)}</pre>
        <pre>
          Knack Raw Value:{' '}
          {JSON.stringify(transformDatePickerStateToKnackJsDateRawValue(watch()), null, 4)}
        </pre>
        */}
      </div>
    </FormProvider>
  );
}
