import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon
} from 'react-icons/hi2';

import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { CommonSourceBanner } from '@/pages/pages/settings-panel/view-settings/common/CommonSourceBanner';
import { ViewWithDetailsActionsSettingsCategory } from '@/pages/pages/settings-panel/view-settings/common/details-actions/ViewWithDetailsActionsSettingsCategory';
import { ViewWithDetailsColumnSettings } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/ViewWithDetailsColumnSettings';
import {
  useDetailsSettingsHelpers,
  type ViewWithDetailsCategoryItem,
  type ViewWithDetailsSettingsCategoryId
} from '@/pages/pages/settings-panel/view-settings/common/useDetailsSettingsHelpers';
import { DetailsDataDisplaySettingsCategory } from '@/pages/pages/settings-panel/view-settings/details/DetailsDataDisplaySettingsCategory';
import { DetailsGeneralSettingsCategory } from '@/pages/pages/settings-panel/view-settings/details/DetailsGeneralSettingsCategory';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import { ViewSettingsCategoryList } from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

export function DetailsSettings() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ViewWithDetails>();
  const { getViewDetailsInput, getActionColumnSettingsPanelTitle, getPanelTitle } =
    useDetailsSettingsHelpers();
  const { viewInputId, isViewInputEditMode, setIsViewInputEditMode } =
    useViewInputEditModeContext();

  const [activeCategoryId, setActiveCategoryId] =
    useState<ViewWithDetailsSettingsCategoryId | null>(null);

  const detailsInputField = getViewDetailsInput(view.columns, viewInputId);

  const DETAILS_SETTINGS_CATEGORIES: ViewWithDetailsCategoryItem[] = [
    {
      id: 'general-settings',
      name: t('pages.element_settings.common.categories.general_settings.name'),
      description: t('pages.element_settings.details.categories.general_settings.description'),
      icon: GeneralSettingsIcon
    },
    {
      id: 'data-display',
      name: t('pages.element_settings.common.categories.data_display.name'),
      description: t('pages.element_settings.details.categories.data_display.description'),
      icon: FieldsIcon
    },
    {
      id: 'actions',
      name: t('pages.element_settings.common.categories.actions.name'),
      description: t('pages.element_settings.details.categories.actions.description'),
      icon: ColumnsIcon
    },
    {
      id: 'column-settings',
      name: getActionColumnSettingsPanelTitle(detailsInputField),
      description: '',
      icon: FieldsIcon,
      shouldHide: true
    }
  ];

  const panelTitle = getPanelTitle(activeCategoryId, DETAILS_SETTINGS_CATEGORIES);
  const shouldHideDivider = activeCategoryId === 'data-display';

  const handleOnBack = () => {
    if (!activeCategoryId) return;

    if (activeCategoryId === 'column-settings') {
      setIsViewInputEditMode(false);
      setActiveCategoryId('data-display');
      return;
    }

    setActiveCategoryId(null);
  };

  useEffect(() => {
    if (isViewInputEditMode && activeCategoryId !== 'column-settings') {
      setActiveCategoryId('column-settings');
    }
  }, [activeCategoryId, isViewInputEditMode]);

  return (
    <CollapsiblePanel.Content
      title={panelTitle}
      onBack={activeCategoryId ? () => handleOnBack() : undefined}
      shouldHideDivider={shouldHideDivider}
    >
      {activeCategoryId ? (
        <>
          {activeCategoryId === 'general-settings' && <DetailsGeneralSettingsCategory />}
          {activeCategoryId === 'data-display' && <DetailsDataDisplaySettingsCategory />}
          {activeCategoryId === 'actions' && <ViewWithDetailsActionsSettingsCategory />}
          {activeCategoryId === 'column-settings' && (
            <ViewWithDetailsColumnSettings columns={view.columns} />
          )}
        </>
      ) : (
        <>
          <CommonSourceBanner view={view} />
          <ViewSettingsCategoryList
            categories={DETAILS_SETTINGS_CATEGORIES}
            onCategorySelect={(selectedCategoryId: string) => {
              setActiveCategoryId(selectedCategoryId as ViewWithDetailsSettingsCategoryId);
            }}
          />
        </>
      )}
    </CollapsiblePanel.Content>
  );
}
