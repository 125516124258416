import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Form, Input, Label } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';
import { z } from 'zod';

import { type KnackObject } from '@/types/schema/KnackObject';
import {
  TASK_RUN_STATUS_OPTIONS,
  TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS,
  TASK_TYPE,
  type KnackTask,
  type TaskKey
} from '@/types/schema/tasks/KnackTask';
import { useDateTimeHelpers } from '@/hooks/helpers/useDateTimeHelpers';
import { useRecordRuleHelpers } from '@/hooks/helpers/useRecordRuleHelpers';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import {
  DEFAULT_TASK_DATE_FORMAT,
  TASK_SCHEDULE_DATE_REGEX,
  TASK_SCHEDULE_TIME_REGEX
} from './constants';
import { TaskActionForm } from './TaskActionForm';
import { TaskScheduleFormSection } from './TaskScheduleFormSection';
import { TaskStatusFormSection } from './TaskStatusFormSection';

type TaskFormProps = {
  table: KnackObject;
  existingTask?: KnackTask;
  onCancel: (shouldShowDisplayDiscardChangesModal?: boolean) => void;
  onTaskSave: (updatedTask: KnackTask) => void;
};

export function TaskForm({ table, existingTask, onCancel, onTaskSave }: TaskFormProps) {
  const [t] = useTranslation();

  const { getFutureDate, getCurrentTime } = useDateTimeHelpers();
  const { getDefaultRecordRule, getRecordRuleFormSchema } = useRecordRuleHelpers();

  const taskFormSchema = z
    .object({
      name: z.string().min(1, {
        message: t('components.data_table.right_sidebar.tasks.errors.name_required')
      }),
      action: getRecordRuleFormSchema(table),
      run_status: z.enum(TASK_RUN_STATUS_OPTIONS),
      type: z.literal(TASK_TYPE),
      object_key: z.custom<KnackObject['key']>(
        (val) => typeof val === 'string' && val.length > 0,
        t('components.data_table.right_sidebar.tasks.errors.object_key_required')
      ),
      schedule: z.object({
        repeat: z.enum(TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS),
        date: z.string().regex(TASK_SCHEDULE_DATE_REGEX, {
          message: t('components.data_table.right_sidebar.tasks.errors.date_error')
        }),
        time: z.string().regex(TASK_SCHEDULE_TIME_REGEX, {
          message: t('components.data_table.right_sidebar.tasks.errors.time_error')
        })
      }),
      key: z.custom<TaskKey>()
    })
    .superRefine((data, context) => {
      // Validate schedule date to be in the future
      const scheduleDate = new Date(data.schedule.date);
      const [hours, minutes] = data.schedule.time.split(':');
      const isPM = minutes.includes('PM');
      const now = new Date();

      scheduleDate.setHours(Number(hours) + (isPM ? 12 : 0));
      scheduleDate.setMinutes(Number(minutes.slice(0, 2))); // Removes the AM/PM
      now.setMinutes(now.getMinutes() - 1); // Subtract 1 minute to avoid the edge case of the current time

      if (data.run_status === 'running' && scheduleDate < now) {
        context.addIssue({
          path: [`schedule.date`],
          message: t('components.data_table.right_sidebar.tasks.errors.task_schedule_date_error'),
          code: 'custom'
        });
      }
    });

  type TaskFormSchema = z.infer<typeof taskFormSchema>;

  const defaultValues: TaskFormSchema = {
    key: `task_${nanoid(10)}`,
    name: '',
    action: getDefaultRecordRule(table),
    run_status: 'running',
    type: 'actions',
    object_key: table.key,
    schedule: {
      repeat: 'daily',
      date: getFutureDate(DEFAULT_TASK_DATE_FORMAT, 1),
      time: getCurrentTime()
    }
  };

  const form = useForm<TaskFormSchema>({
    resolver: zodResolver(taskFormSchema),
    defaultValues: existingTask || defaultValues
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = form;

  const onSubmit = (updatedTask: TaskFormSchema) => {
    onTaskSave(updatedTask);
  };

  return (
    <div className="space-y-4 rounded-lg bg-muted">
      <FormProvider {...form}>
        <form data-testid="task-form" id="task-form" onSubmit={handleSubmit(onSubmit)}>
          <Form.Section className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Label htmlFor="task-name-input" className="font-medium">
                {t('components.data_table.right_sidebar.tasks.name_label')}
              </Label>
              <Input
                id="task-name-input"
                data-testid="task-name-input"
                className="w-full"
                intent={errors?.name ? 'destructive' : undefined}
                {...register('name')}
              />
              <FormErrorMessage errors={errors} name="name" />
            </div>
            <TaskStatusFormSection />
            <TaskScheduleFormSection />
          </Form.Section>

          <Divider className="my-6" />

          <TaskActionForm recordRule={watch('action')} sourceObject={table} />

          <div className="mt-4 flex justify-end gap-2">
            <Button
              intent="secondary"
              onClick={() => onCancel(existingTask && form.formState.isDirty)}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              type="submit"
              data-testid="task-submit-button"
              disabled={!form.formState.isDirty}
            >
              {t('actions.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
