import {
  Controller,
  useFormContext,
  type ControllerRenderProps,
  type FieldValues
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, RichTextEditor, Select, Textarea } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { FieldSelect } from '@/components/FieldSelect';
import { DateTimeInput } from '@/components/import/confirm-import/date-time/DateTimeInput';
import { TimerInput } from '@/components/import/confirm-import/timer/TimerInput';
import { AddressInput } from '@/components/inputs/address/AddressInput';
import { BooleanInput } from '@/components/inputs/BooleanInput';
import { ConnectionInput } from '@/components/inputs/ConnectionInput';
import { CurrencyInput } from '@/components/inputs/CurrencyInput';
import { EmailInput } from '@/components/inputs/EmailInput';
import { FileInput } from '@/components/inputs/FileInput';
import { LinkInput } from '@/components/inputs/LinkInput';
import { MultipleChoiceInput } from '@/components/inputs/MultipleChoiceInput';
import { NumberInput } from '@/components/inputs/NumberInput';
import { PasswordInput } from '@/components/inputs/PasswordInput';
import { PersonInput } from '@/components/inputs/PersonInput';
import { PhoneInput } from '@/components/inputs/PhoneInput';
import { RatingInput } from '@/components/inputs/RatingInput';
import { SignatureInput } from '@/components/inputs/SignatureInput';
import { UserRolesInput } from '@/components/inputs/UserRolesInput';
import { type ConditionalRuleFormSchema } from '@/pages/tables/toolkit-sidebar/rules/conditional-rules/ConditionalRulesForm';
import { getFilteredFieldActionValueFields } from '@/pages/tables/toolkit-sidebar/rules/conditional-rules/utils';

interface ValueInputProps {
  field: KnackField;
  formFieldName: string;
  table: KnackObject;
}

interface ActionFieldInputProps {
  availableFields: KnackField[];
  formFieldName: string;
}

export function ValueInput({ field, formFieldName, table }: ValueInputProps) {
  const { t } = useTranslation();
  const { register, setValue, watch } = useFormContext();
  const value = watch(formFieldName);
  const fieldType = field.type;

  const inputHtmlId = `${field.key}-${fieldType}-input`;

  if (fieldType === 'rich_text') {
    return (
      <div>
        <RichTextEditor
          content={value}
          data-testid="conditional-rule-action-value-rich-text"
          onUpdate={({ editor }) => {
            const content = editor.isEmpty ? '' : editor.getHTML();
            setValue(formFieldName, content);
          }}
        />
      </div>
    );
  }

  if (fieldType === 'rating') {
    return (
      <div data-testid="conditional-rule-action-value-rating">
        <RatingInput targetField={field} name={formFieldName} />
      </div>
    );
  }

  if (fieldType === 'address') {
    return (
      <div data-testid="conditional-rule-action-value-address">
        <AddressInput id={inputHtmlId} targetField={field} name={formFieldName} />
      </div>
    );
  }

  if (fieldType === 'name') {
    return (
      <div data-testid="conditional-rule-action-value-name">
        <PersonInput id={inputHtmlId} targetField={field} name={formFieldName} />
      </div>
    );
  }

  if (fieldType === 'phone') {
    return (
      <div data-testid="conditional-rule-action-value-phone">
        <PhoneInput id={inputHtmlId} targetField={field} name={formFieldName} />
      </div>
    );
  }

  if (fieldType === 'file' || fieldType === 'image') {
    return (
      <div data-testid="conditional-rule-action-value-file-image">
        <FileInput targetField={field} name={formFieldName} />
      </div>
    );
  }

  if (fieldType === 'email') {
    return (
      <div data-testid="conditional-rule-action-value-email">
        <EmailInput id={inputHtmlId} targetField={field} name={formFieldName} />
      </div>
    );
  }
  if (fieldType === 'link') {
    return (
      <div data-testid="conditional-rule-action-value-link">
        <LinkInput id={inputHtmlId} targetField={field} name={formFieldName} />
      </div>
    );
  }

  if (fieldType === 'currency') {
    return (
      <div data-testid="conditional-rule-action-value-currency">
        <CurrencyInput id={inputHtmlId} targetField={field} name={formFieldName} />
      </div>
    );
  }

  if (fieldType === 'number') {
    return (
      <div data-testid="conditional-rule-action-value-number">
        <NumberInput id={inputHtmlId} name={formFieldName} format={field.format} />
      </div>
    );
  }

  if (fieldType === 'signature') {
    return (
      <div data-testid="conditional-rule-action-value-signature">
        <SignatureInput name={formFieldName} />
      </div>
    );
  }

  if (fieldType === 'date_time') {
    return (
      <div data-testid="conditional-rule-action-value-date-time">
        <DateTimeInput id={inputHtmlId} targetField={field} name={formFieldName} />
      </div>
    );
  }

  if (fieldType === 'timer') {
    return (
      <div data-testid="conditional-rule-action-value-timer">
        <TimerInput id={inputHtmlId} targetField={field} name={formFieldName} />
      </div>
    );
  }
  if (fieldType === 'boolean') {
    return (
      <div data-testid="conditional-rule-action-value-boolean">
        <BooleanInput id={inputHtmlId} targetField={field} name={formFieldName} />
      </div>
    );
  }

  if (fieldType === 'multiple_choice') {
    return (
      <div data-testid="conditional-rule-action-value-multiple-choice">
        <MultipleChoiceInput id={inputHtmlId} targetField={field} name={formFieldName} />
      </div>
    );
  }
  if (fieldType === 'user_roles') {
    return (
      <div data-testid="conditional-rule-action-value-user-roles">
        <UserRolesInput name={formFieldName} id={inputHtmlId} selectedRoleObject={table} />
      </div>
    );
  }

  if (fieldType === 'connection') {
    return (
      <div data-testid="conditional-rule-action-value-connection">
        <ConnectionInput id={inputHtmlId} field={field} name={formFieldName} />
      </div>
    );
  }
  if (fieldType === 'password') {
    return (
      <div data-testid="conditional-rule-action-value-password">
        <PasswordInput id={inputHtmlId} name={formFieldName} />
      </div>
    );
  }
  return (
    <Textarea
      data-testid="conditional-rule-action-value-textarea"
      placeholder={t('components.data_table.right_sidebar.conditional_rules.enter_value')}
      {...register(formFieldName)}
    />
  );
}

export function ActionFieldInput({ availableFields, formFieldName }: ActionFieldInputProps) {
  const { setValue, getValues } = useFormContext();

  const isCurrentFieldValid = !!availableFields.find((f) => f.key === getValues(formFieldName));
  if (!isCurrentFieldValid && availableFields.length > 0) {
    setValue(formFieldName, availableFields[0].key);
  }

  return (
    <Controller
      name={formFieldName}
      render={({ field }) => (
        <FieldSelect
          id="action-value-select"
          data-testid="action-value-select"
          fields={availableFields}
          onFieldChange={field.onChange}
          defaultValue={field.value || availableFields[0].key}
          {...field}
        />
      )}
    />
  );
}

interface ConditionalRuleFormActionValueProps {
  defaultValues: ConditionalRuleFormSchema;
  table: KnackObject;
}

export function ConditionalRuleFormActionValue({
  defaultValues,
  table
}: ConditionalRuleFormActionValueProps) {
  const { t } = useTranslation();

  const {
    formState: { errors },
    watch,
    setValue
  } = useFormContext();

  const actionType = watch('values.type');
  const knackFieldKey = watch('values.field');

  const knackField = table.fields.find((f) => f.key === knackFieldKey);
  if (!knackField) {
    return null;
  }

  const filteredFieldActionValueFields = getFilteredFieldActionValueFields(
    knackField,
    table.fields
  );

  const CONDITIONAL_ACTION_TYPES = [
    {
      key: 'value',
      name: t('components.data_table.right_sidebar.conditional_rules.types.value')
    },
    {
      key: 'record',
      name: t('components.data_table.right_sidebar.conditional_rules.types.record')
    }
  ];

  const handleValueChange = (
    field: ControllerRenderProps<FieldValues, 'values.type'>,
    newValue: string
  ) => {
    field.onChange(newValue);

    if (newValue === 'record') {
      if (filteredFieldActionValueFields.length > 0) {
        setValue('values.input', filteredFieldActionValueFields[0].key);
      } else {
        setValue('values.input', '');
      }
    }

    setValue('values.value', '');
  };

  return (
    <Form.Section>
      <Form.Label htmlFor="message">{t('actions.set')}</Form.Label>
      <div className="space-y-2">
        <Controller
          name="values.type"
          render={({ field }) => (
            <Select
              onValueChange={(newValue) => handleValueChange(field, newValue)}
              value={field.value}
              defaultValue={defaultValues.values.type}
            >
              <Select.Trigger
                id="action-type-select"
                placeholder={t('actions.select')}
                className="w-full truncate rounded-lg"
                data-testid="action-type-select"
                aria-label={t(
                  'components.data_table.right_sidebar.conditional_rules.select_action_type'
                )}
                {...field}
              />
              <Select.Content data-testid="action-type-content">
                {CONDITIONAL_ACTION_TYPES.map((type) => (
                  <Select.Item
                    key={type.key}
                    value={type.key}
                    data-testid={type.key}
                    disabled={type.key === 'record' && filteredFieldActionValueFields.length === 0}
                  >
                    {type.name}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
        {actionType === 'value' && (
          <>
            <ValueInput field={knackField} formFieldName="values.value" table={table} />

            {errors.action?.message && (
              <Form.Message type="error" data-testid="conditional-rule-message-error">
                {errors.action.message as string}
              </Form.Message>
            )}
          </>
        )}
        {actionType === 'record' && (
          <ActionFieldInput
            availableFields={filteredFieldActionValueFields}
            formFieldName="values.input"
          />
        )}
      </div>

      {errors && (
        <Form.Message type="error" data-testid="conditional-rule-message-error">
          {errors.root?.message}
        </Form.Message>
      )}
    </Form.Section>
  );
}
