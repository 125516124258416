import { useLayoutEffect } from 'react';
import { autoUpdate, flip, size, useFloating } from '@floating-ui/react';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { formatDateTimeRawValue } from '@/components/data-table/display/fields/date-time/formatters/dateFormatter';
import { useDateTimeKeyboardEvents } from '@/components/data-table/display/fields/date-time/useDateTimeKeyboardEvents';
import { type DateTimeField as DateTimeFieldDisplay } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { KnackDatePicker } from '@/components/knack-date-picker/KnackDatePicker';

export function DateTimeEdit(props: FieldRenderProps<DateTimeFieldDisplay>) {
  const { rawValue, rowId, fieldId, type } = props;
  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const selectedCell = useDataTableStore().use.selectedCell();
  if (!selectedCell) throw new Error('No selected cell');

  const { saveCell, setIsEditing } = useDataTableStore().use.actions();

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      // Fill the available height for big lists
      flip({
        crossAxis: true
      }),
      size({
        padding: 25,
        apply({ availableHeight, availableWidth, elements }) {
          elements.floating.style.maxHeight = `${availableHeight}px`;
          elements.floating.style.maxWidth = `${availableWidth}px`;
        }
      })
    ],
    whileElementsMounted: autoUpdate
  });

  useDateTimeKeyboardEvents(refs.floating);

  // We focus the input when the cell is selected, this way you can write ritght away in the input even if you are not in editing mode.
  useLayoutEffect(() => {
    // As the DatePicker could have multiple inputs we need to focus the first one
    // We could modify the DatePicker to return the ref of the input but since this is only needed here I preferred to not over complicate the date picker.
    const inputElement = refs.floating?.current?.getElementsByTagName('input')[0];

    if (inputElement) {
      requestAnimationFrame(() => {
        inputElement.focus();
      });
    }
  }, [refs.floating, selectedCell.isEditing]);

  return (
    <>
      <button
        type="button"
        className={cn('flex size-full flex-row bg-base p-2', {
          'bg-base': selectedCell.isEditing
        })}
        ref={refs.setReference}
        data-testid={`date-time-edit-${rowId}-${fieldId}`}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        <p className="truncate">{formatDateTimeRawValue(rawValue, currentField.format)}</p>
      </button>
      <div
        ref={refs.setFloating}
        style={floatingStyles}
        className={cn('mt-1 flex min-h-fit min-w-fit rounded-lg border border-default bg-base', {
          // When you are not editing the cell we want the input to be focused but not visible
          // We also want to avoid any interaction in the input or other elements so we set pointer-events-none
          'pointer-events-none opacity-0 [&_*_]:pointer-events-none': !selectedCell.isEditing
        })}
        data-testid={`date-time-date-picker-${rowId}-${fieldId}`}
      >
        <div className="overflow-auto p-2">
          <KnackDatePicker
            isRequired={currentField.required}
            fieldFormat={currentField.format}
            defaultValue={rawValue}
            onChange={async (newRawValue) => {
              setIsEditing(true);
              await saveCell(rowId, fieldId, newRawValue, {
                value: '',
                rawValue: newRawValue
              });
            }}
          />
        </div>
        {
          // Show the error inside the popover when the cell is editing
        }
        {selectedCell.isEditing && (
          <CellErrors rowId={rowId} fieldId={fieldId} testIdPrefix="date-time-edit-error" />
        )}
      </div>
      {!selectedCell.isEditing && (
        <CellErrors rowId={rowId} fieldId={fieldId} testIdPrefix="date-time-edit-error" />
      )}
    </>
  );
}
