import { Controller, useFormContext } from 'react-hook-form';
import { DateTimePicker } from '@knack/asterisk-react';
import { type DateTimePickerProps } from '@knack/asterisk-react/dist/src/components/date-time-picker/DateTimePicker';

import { FormErrorMessage } from '@/components/errors/FormErrorMessage';

export function DatePickerFormWrapper(
  props: Pick<DateTimePickerProps, 'format' | 'mode' | 'defaultValue'> & { name: string }
) {
  const { name, mode, defaultValue } = props;
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <div>
      <Controller
        defaultValue={defaultValue}
        render={({ field, formState }) => (
          <DateTimePicker
            intent={formState.errors[field.name] ? 'destructive' : 'default'}
            {...({
              ...props,
              onChange: field.onChange,
              value: field.value
            } as DateTimePickerProps)} // Since the props are dynamic, we need to cast it to the correct type
          />
        )}
        control={control}
        name={name}
      />

      {mode === 'range' && (
        <>
          <FormErrorMessage className="mt-1" errors={errors} name={`${name}.from`} />
          <FormErrorMessage className="mt-1" errors={errors} name={`${name}.to`} />
        </>
      )}
      {mode === 'single' && <FormErrorMessage className="mt-1" errors={errors} name={name} />}
    </div>
  );
}
